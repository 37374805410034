define("katanalotis-microsite/templates/shops", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8xX17mFK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"shops\"],[10,\"class\",\"container-fluid navbar-top-offset px-0\"],[8],[0,\"\\n    \"],[1,[28,\"map-component\",null,[[\"product\",\"merchants\",\"controller\",\"shops\",\"regionQuery\",\"avgPrice\"],[[24,[\"product\"]],[24,[\"merchants\"]],[23,0,[]],[24,[\"filteredShopsByRegion\"]],[24,[\"regionQuery\"]],[24,[\"avgPrice\"]]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "katanalotis-microsite/templates/shops.hbs"
    }
  });
});