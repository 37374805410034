define("katanalotis-microsite/templates/components/map-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fGxMfmQt",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"script\",true],[8],[0,\"\\n    \\n    function getDirections(x, y) {\\n        //Navigate from user location to selected shop\\n        if(navigator.userAgent.toLowerCase().indexOf(\\\"iphone\\\") > -1 || navigator.userAgent.toLowerCase().indexOf(\\\"ipad\\\") > -1){\\n            window.open(\\\"https://maps.apple.com/?daddr=\\\"+x+\\\",\\\"+y,'_blank');\\n        }else{\\n            window.open(\\\"https://maps.google.com/maps?daddr=\\\"+x+\\\",\\\"+y,'_blank');\\n\\n        }\\n    }\\n    \"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"map\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "katanalotis-microsite/templates/components/map-component.hbs"
    }
  });
});