define("katanalotis-microsite/controllers/fuels-results", ["exports", "ember-resolver", "katanalotis-microsite/config/environment"], function (_exports, _emberResolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['merchant', 'date'],
    isMobile: Ember.computed(function () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    }),
    qrDate: Ember.computed('date', 'yesterdayDate', 'requestCount', function () {
      console.log(this.get('requestCount'));
      return this.get('date') && this.get('requestCount') < 2 ? this.get('date') : this.get('yesterdayDate');
    }),
    qrMerchant: Ember.computed('fuelType', function () {
      return this.get('fuelType').id;
    }),
    qrUrl: Ember.computed('qrDate', 'qrMerchant', function () {
      return `https://e-katanalotis.gov.gr/fuels-results?date=${this.get('qrDate')}&merchant=${this.get('qrMerchant')}`;
    }),
    filterExpanded: false,
    resultsService: Ember.inject.service('results-preview'),
    selectedPage: 1,
    itemsPerPage: 15,
    sorting: 'ascending',
    fuelCategories: Ember.computed(function () {
      return this.get('store').peekAll('fuelstation-category');
    }),
    counties: Ember.computed('model', function () {
      return [];
    }),
    companies: Ember.computed('model', function () {
      return [];
    }),
    stations: Ember.computed(function () {
      return this.get('store').peekAll('fuelstationPin');
    }),
    /*  fuels: Ember.computed('companies.@each.checked', 'counties.@each.checked', 'model', 'sorting', 'model.@each', function () {
         console.log('fuels-results')
         var model = this.get('model')
         var countiesFilter = this.get('counties').filter(function (item) {
             if (item.checked) {
                 return true
             }
             return false
         }).map(x => x.name)
         var companiesFilter = this.get('companies').filter(function (item) {
             if (item.checked) {
                 return true
             }
             return false
         }).map(x => x.name)
           model = model.filter(function (item) {
             if (item.price && item.price != "0") {
                 if ((!countiesFilter.length && !companiesFilter.length) ||
                     ((countiesFilter.length && countiesFilter.includes(item.county)) || !countiesFilter.length) &&
                     ((companiesFilter.length && companiesFilter.includes(item.company)) || !companiesFilter.length)) {
                     return true
                 }
             }
             return false
         })
           if (this.get('sorting') == 'ascending') {
             return model.sortBy('price')
         } else {
             return model.sortBy('price').reverse()
         }
       }), */
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    formatDate(date) {
      return [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
    },
    fakeDate: Ember.computed(function () {
      return this.formatDate(new Date());
    }),
    yesterdayDate: Ember.computed(function () {
      var d = new Date();
      d.setDate(d.getDate() - 1);
      return this.formatDate(d);
    }),
    twoDaysAgoDate: Ember.computed(function () {
      var d = new Date();
      d.setDate(d.getDate() - 2);
      return this.formatDate(d);
    }),
    avgPriceDate: Ember.computed('date', 'requestCount', function () {
      return this.get('date') && this.get('requestCount') < 2 && this.get('merchant') == this.get('fuelType').id ? this.get('date') : this.get('twoDaysAgoDate');
    }),
    fuels: Ember.computed('companies.@each.checked', 'cityOptions.@each.checked', 'model', 'sorting', 'model.@each', function () {
      var model = this.get('model');
      var cityOptionsFilter = this.get('cityOptions').filter(x => x.checked).map(x => x.name);
      model = model.filter(function (item) {
        if (item.price && item.price != "0") {
          if (!cityOptionsFilter.length || cityOptionsFilter.length && cityOptionsFilter.includes(item.city)) {
            return true;
          }
        }
        return false;
      });
      return model;
    }),
    fuelAveragePrice: Ember.computed('fuels', function () {
      const prices = this.get('fuels').map(x => x.price);
      const avgPrice = prices.reduce((a, b) => a + b, 0) / prices.length;
      return avgPrice.toFixed(3);
    }),
    fuelsPaginated: Ember.computed('fuels', 'fuels.@each', 'selectedPage', function () {
      var indexStart = (this.get('selectedPage') - 1) * this.get('itemsPerPage');
      return this.get('fuels').slice(indexStart, this.get('itemsPerPage') + indexStart);
    }),
    lastPage: Ember.computed('fuels', 'fuels.@each', 'selectedPage', function () {
      return this.totalPages() == 1 ? null : this.totalPages();
    }),
    midPages: Ember.computed('fuels', 'fuels.@each', 'selectedPage', function () {
      var selectedPage = this.get('selectedPage');
      var totalPages = this.totalPages();
      var lastPage = this.get('lastPage');
      if (totalPages > 2) {
        var midPages = [];
        if (selectedPage - 1 > 1) {
          midPages = [selectedPage];
          midPages.unshift(selectedPage - 1);
        } else if (selectedPage - 1 > 0) {
          midPages = [selectedPage];
        }
        if (lastPage - selectedPage > 1) {
          midPages.push(selectedPage + 1);
        } else if (lastPage - selectedPage > 0) {
          midPages = [selectedPage];
        }
        if (selectedPage - 1 > 1) {
          Ember.set(this, 'showPrevDots', true);
        } else {
          Ember.set(this, 'showPrevDots', false);
        }
        if (lastPage - selectedPage > 1) {
          Ember.set(this, 'showAfterDots', true);
        } else {
          Ember.set(this, 'showAfterDots', false);
        }
        return midPages;
      }
      return null;
    }),
    cityOptions: Ember.computed('warply_sdk.fuelFiltersSet', function () {
      return this.get('store').peekAll('fuel-filter').map(x => x.city)[0].map(x => {
        return {
          name: x,
          checked: false
        };
      });
    }),
    totalPages() {
      return Math.ceil(this.get('fuels').length / this.get('itemsPerPage'));
    },
    successGetFuels_old(data) {
      var self = this;
      var count = this.get('count') || 0;
      var stations = this.get('store').peekAll('fuelstationPin').map(x => x.toJSON());
      var fuels = data.context.MAPP_PRODUCTS.result.products;
      var counties = [];
      var companies = [];
      if (this.get('store').peekAll('fuelstation').content.length) {
        this.get('store').peekAll('fuelstation').forEach(function (item) {
          Ember.run.once(function () {
            item.deleteRecord();
          });
        });
      }
      for (var i = 0; i < fuels.length; i++) {
        var fuel = fuels[i];
        var list = fuel.prices[0].price;
        for (const key in list) {
          var price = list[key];
          if (price && price.constructor != Object && price != "0") {
            var station = stations.find(x => x.store_id == key);
            if (station) {
              if (!counties.includes(station.county)) {
                counties.push(station.county);
              }
              if (!companies.includes(station.company)) {
                companies.push(station.company);
              }
              count++;
              station.price = price;
              station.type = this.get('fuelType').name;
              self.get('store').createRecord('fuelstation', station);
            }
          }
        }
        Ember.set(this, 'counties', counties.map(x => ({
          name: x,
          checked: false
        })));
        Ember.set(this, 'companies', companies.map(x => ({
          name: x,
          checked: false
        })));
        Ember.set(this, 'selectedPage', 1);
      }
    },
    successGetFuels(data) {
      const self = this;
      var result = data.context.MAPP_SHOPS.result;
      if (this.get('store').peekAll('fuelstation').content.length) {
        this.get('store').peekAll('fuelstation').forEach(function (item) {
          Ember.run.once(function () {
            item.deleteRecord();
          });
        });
      }
      $.each(result, function (i, item) {
        item.id = item.uuid;
        item.price = item.product[self.get('fuelType').id];
        try {
          self.store.createRecord('fuelstation', item);
        } catch (e) {
          console.log(e);
        }
      });
      Ember.set(this.get('warply_sdk'), 'fuelType', this.get('fuelType'));
      Ember.set(this, 'requestCount', this.get('requestCount') + 1);
    },
    errorGetFuels(data) {
      console.log(data);
    },
    requestCount: 0,
    fuelType_obs: Ember.observer('fuelType', function () {
      const self = this;
      let date;
      if (this.get('date') && this.get('requestCount') == 0) {
        date = this.get('date');
      } else {
        date = this.get('yesterdayDate');
      }
      this.get('warply_sdk').post_context(JSON.stringify({
        "shops": {
          "action": "retrieve_multilingual",
          "merchant_id": _environment.default.merchant_id,
          "active": true,
          "tags": null,
          "language": 'el',
          "product_uuids": [this.get('fuelType').id],
          "last_product_update": date
        }
      }), this.successGetFuels.bind(this), this.errorGetFuels.bind(this));
    }),
    actions: {
      decrementPage() {
        if (this.get('selectedPage') != 1) {
          Ember.set(this, 'selectedPage', this.get('selectedPage') - 1);
        }
      },
      incrementPage() {
        if (this.get('selectedPage') != this.totalPages()) {
          Ember.set(this, 'selectedPage', this.get('selectedPage') + 1);
        }
      },
      selectPage(page) {
        Ember.set(this, 'selectedPage', page);
      }
    }
  });
});