define("katanalotis-microsite/controllers/fuels-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    fuelstations: Ember.computed('trigger', function () {
      var stations = this.get('store').peekAll('fuelstation').filter(x => x.longitude && x.latitude);
      const selectedRegion = this.get('selectedRegion');
      const selectedCity = this.get('selectedCity');
      if (selectedRegion) {
        stations = stations.filter(x => x.region == selectedRegion);
      }
      if (selectedCity) {
        stations = stations.filter(x => x.city == selectedCity);
      }
      return stations;
    }),
    fuelType: Ember.computed('warply_sdk.fuelType', function () {
      if (this.get('warply_sdk').fuelType) {
        return this.get('warply_sdk').fuelType.name;
      }
    }),
    storeId: Ember.computed('model', function () {
      return this.get('model');
    }),
    cityOptions: Ember.computed('warply_sdk.fuelFiltersSet', function () {
      return this.get('store').peekAll('fuel-filter').map(x => x.city)[0];
    }),
    regionOptions: Ember.computed('warply_sdk.fuelFiltersSet', function () {
      return this.get('store').peekAll('fuel-filter').map(x => x.region)[0];
    }),
    actions: {
      toggleFilter() {
        Ember.set(this, 'filterMinimized', !this.get("filterMinimized"));
      },
      toggleFilterTrigger() {
        Ember.set(this, 'trigger', !this.get("trigger"));
      }
    }
  });
});