define("katanalotis-microsite/components/image-with-fallback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["image-with-fallback"],
    // Default values for properties
    src: null,
    fallbackSrc: null,
    actions: {
      handleImageError() {
        // If there's an error loading the main image, set the src to the fallback image
        this.set("src", this.fallbackSrc);
      }
    }
  });
});