define("katanalotis-microsite/helpers/available-markets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function availableMarkets(params /*, hash*/) {
    const prices = params[0].map(price => price.price).filter(price => price != 0);
    return prices.length;
  });
});