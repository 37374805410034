define("katanalotis-microsite/services/warply-sdk", ["exports", "katanalotis-microsite/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* Warply SDK v0.1 */
  var _default = _exports.default = Ember.Service.extend({
    base_url: _environment.default.engageHost,
    web_id: null,
    app_uuid: _environment.default.app_uuid,
    api_key: null,
    retries: 0,
    init: function () {
      "use strict";

      this._super(...arguments);
      var self = this;
      var web_id = this.get("web_id") || localStorage.getItem("web_id");
      var api_key = this.get("api_key") || localStorage.getItem("api_key");
      if (!(web_id && api_key)) {
        this.register(function (data) {
          if (data.hasOwnProperty("status") && data.status === "1" && data.hasOwnProperty("context")) {
            if (data.context.hasOwnProperty("api_key")) {
              self.set("api_key", data.context.api_key);
              localStorage.setItem("api_key", data.context.api_key);
            }
            if (data.context.hasOwnProperty("web_id")) {
              self.set("web_id", data.context.web_id);
              localStorage.setItem("web_id", data.context.web_id);
            }
          }
        }.bind(this), function () {
          console.log("Register Failed");
        }.bind(this));
      }
      if (!(this.get("web_id") && this.get("api_key"))) {
        this.set("api_key", localStorage.getItem("api_key"));
        this.set("web_id", localStorage.getItem("web_id"));
      }
      Ember.run.scheduleOnce('afterRender', this, function () {
        $('body').on('click', '[data-rmd-action]', function (e) {
          e.preventDefault();
          var action = $(this).data('rmd-action');
          var $this = $(this);
          switch (action) {
            /*-------------------------------------------
            		Toggle Block
            ---------------------------------------------*/
            case 'block-open':
              var rmdTarget = $(this).data('rmd-target');
              var rmdBackdrop = $(this).data('rmd-backdrop-class') || '';
              $(rmdTarget).addClass('toggled');
              $('body').addClass('block-opened').append('<div data-rmd-action="block-close" data-rmd-target=' + rmdTarget + ' class="rmd-backdrop rmd-backdrop--dark ' + rmdBackdrop + '" />');
              $('.rmd-backdrop').fadeIn(300);
              break;
            case 'block-close':
              var rmdTarget = $(this).data('rmd-target');
              $(rmdTarget).removeClass('toggled');
              $('body').removeClass('block-opened');
              $('.rmd-backdrop').fadeOut(300);
              setTimeout(function () {
                $('.rmd-backdrop').remove();
              }, 300);
              break;

            /*-------------------------------------------
            		Navigation close
            ---------------------------------------------*/
            case 'navigation-close':
              $('.navigation').removeClass('toggled');
              $('body').removeClass('block-opened');
              $('.rmd-backdrop').fadeOut(300);
              setTimeout(function () {
                $('.rmd-backdrop').remove();
              }, 300);
              break;
            case 'dropdown-open':
              console.log('click');
              $('.navigation__dropdown.visible-xs .navigation__drop-menu').toggleClass('hidden');
              $('.navigation__dropdown.visible-xs').toggleClass('opened');
          }
        });
      });
    },
    prepare_request: function (xhr) {
      "use strict";

      var now = new Date();
      var date_format = now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate() + ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();
      xhr.setRequestHeader('loyalty-web-id', this.get("web_id"));
      xhr.setRequestHeader('loyalty-date', date_format);
      xhr.setRequestHeader('loyalty-signature', sha256_digest(this.get("api_key") + date_format));
      xhr.setRequestHeader('Channel', 'web');
    },
    register: function (succ_cb, error_cb) {
      "use strict";

      $.ajax({
        url: 'https://engage.warp.ly/api/mobile/v2/' + this.get("app_uuid") + '/register/',
        type: 'GET',
        async: false,
        success: function (data) {
          succ_cb(data);
        },
        error: function () {
          error_cb();
        }
      });
    },
    add_tags: function (...tags) {
      this.send_tag_event("add_tags", tags);
    },
    remove_tags: function (...tags) {
      this.send_tag_event("remove_tags", tags);
    },
    rewrite_tags: function (...tags) {
      this.send_tag_event("rewrite_tags", tags);
    },
    send_tag_event: function (action, tags) {
      var data = {};
      data.tags = {};
      data.tags.action = action;
      data.tags.tags = tags;
      this.post_context(JSON.stringify(data));
    },
    add_event: function (page_id, event) {
      var data = {};
      data.inapp_analytics = {};
      data.inapp_analytics.page_id = page_id;
      data.inapp_analytics.event_id = event;
      data.inapp_analytics.time_submitted = Date.now().toString();
      this.post_context(JSON.stringify(data));
    },
    get_context: function (succ_cb, error_cb) {
      // var self = this;
      // self.get("spinner").task();
      $.ajax({
        url: 'https://engage.warp.ly/api/mobile/v2/' + this.get("app_uuid") + '/context/',
        type: 'GET',
        success: function (data) {
          succ_cb(data);
        },
        error: function () {
          error_cb();
        },
        beforeSend: this.prepare_request
        // }).always(function(){self.get("spinner").endTask();});
      });
    },
    get_method: function (url, succ_cb, error_cb) {
      // var self = this;
      // self.get("spinner").task();
      $.ajax({
        url: url,
        type: 'GET',
        success: function (data) {
          succ_cb(data);
        },
        error: function () {
          error_cb();
        },
        beforeSend: this.prepare_request
        // }).always(function(){self.get("spinner").endTask();});
      });
    },
    post_context: function (payload, succ_cb, error_cb) {
      var self = this;
      var app_uuid = this.get('app_uuid');
      // self.get("spinner").task();
      try {
        $.ajax({
          url: 'https://engage.warp.ly/api/mobile/v2/' + app_uuid + '/context/',
          type: 'POST',
          async: false,
          data: payload,
          success: function (data) {
            if (succ_cb) {
              if (data.hasOwnProperty('status') && data.status === "9") {
                self.set('retries', self.get('retries') + 1);
                if (self.get('retries') <= 1) {
                  $.ajax({
                    url: 'https://engage.warp.ly/api/mobile/v2/' + this.get("app_uuid") + '/register/',
                    type: 'GET',
                    async: false,
                    success: function (data) {
                      if (data.hasOwnProperty("status") && data.status === "1" && data.hasOwnProperty("context")) {
                        if (data.context.hasOwnProperty("api_key")) {
                          self.set("api_key", data.context.api_key);
                          localStorage.setItem("api_key", data.context.api_key);
                        }
                        if (data.context.hasOwnProperty("web_id")) {
                          self.set("web_id", data.context.web_id);
                          localStorage.setItem("web_id", data.context.web_id);
                        }
                        self.post_context(payload, succ_cb, error_cb);
                      }
                    }.bind(this),
                    error: function () {
                      error_cb();
                    }
                  });
                }
              }
              succ_cb(data);
            }
          }.bind(self),
          error: function () {
            if (succ_cb) {
              error_cb();
            }
          },
          beforeSend: this.prepare_request.bind(this),
          contentType: "application/json"
        }).always(function () {
          // self.get("spinner").endTask();
        });
      } catch (e) {
        console.log(e);
        // self.get("spinner").endTask();
      }
    }
  });
});