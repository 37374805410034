define("katanalotis-microsite/routes/global-map", ["exports", "ember-resolver", "katanalotis-microsite/config/environment"], function (_exports, _emberResolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    formatDate(date) {
      return [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
    },
    yesteradayDate: Ember.computed(function () {
      var d = new Date();
      d.setDate(d.getDate() - 1);
      return this.formatDate(d);
    }),
    successGetFuels(data) {
      const self = this;
      var result = data.context.MAPP_SHOPS.result;
      $.each(result, function (i, item) {
        let longitude = item.longitude;
        let latitude = item.latitude;
        if (!['undefined', '0', 'NaN'].includes(longitude) && !['undefined', '0', 'NaN'].includes(latitude) && typeof longitude != 'undefined' && typeof latitude != 'undefined' && !isNaN(longitude) && !isNaN(latitude) && longitude && latitude) {
          item.id = item.uuid;
          try {
            self.store.createRecord('fuelstation-pin', item);
          } catch (e) {
            console.log(e);
          }
        }
      });
      Ember.set(this.controllerFor('global-map'), 'fuelstations', this.get('store').peekAll('fuelstation-pin'));
    },
    errorGetFuels(data) {
      console.log(data);
    },
    model() {
      const self = this;
      if (!this.get('store').peekAll('fuelstation-pin').length) {
        this.get('warply_sdk').post_context(JSON.stringify({
          "shops": {
            "action": "retrieve_multilingual",
            "merchant_id": _environment.default.merchant_id,
            "active": true,
            "tags": null,
            "language": 'el',
            "last_product_update": this.get('yesteradayDate')
          }
        }), this.successGetFuels.bind(this), this.errorGetFuels.bind(this));
      }
    },
    actions: {
      didTransition() {
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
      }
    }
  });
});