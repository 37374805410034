define("katanalotis-microsite/components/cookies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didRender() {
      this._super(...arguments);
      let cookie = localStorage.getItem('cookiesAccepted');
      if (cookie == 'true') {
        Ember.set(this, 'cookiesAccepted', true);
      }
    },
    actions: {
      hideCookies() {
        Ember.set(this, 'cookiesAccepted', true);
        localStorage.setItem('cookiesAccepted', true);
      }
    }
  });
});