define("katanalotis-microsite/components/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      closeCart() {
        this.cart.closeCart();
      },
      openCookiesModal() {
        $('#cookieModal').modal('show');
      }
    }
  });
});