define("katanalotis-microsite/helpers/lowest-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function lowestPrice(params /*, hash*/) {
    if (params[1] && params[2]) {
      return params[1][0].price.toFixed(2);
    }
    const prices = params[0].map(price => price.price).filter(price => price != 0);
    var least = Math.min(...prices);
    return least.toFixed(2);
  });
});