define("katanalotis-microsite/models/price-per-day", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    Avg_Price: _emberData.default.attr('number'),
    Date: _emberData.default.attr('string'),
    Day_Month: _emberData.default.attr('string'),
    Full_Date: _emberData.default.attr('string'),
    Min_Price: _emberData.default.attr('number'),
    Min_Markets: _emberData.default.attr()
  });
});