define("katanalotis-microsite/helpers/contract-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function contractValue(params /*, hash*/) {
    try {
      return (params[0] + params[1] / 1000 * params[2]).toFixed(2);
    } catch (e) {
      console.error(e);
      return 0;
    }
  });
});