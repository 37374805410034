define("katanalotis-microsite/templates/components/product-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "44fnHG42",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"search-container\"],[8],[0,\"\\n\"],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"search-input-container\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"selected\",\"renderInPlace\",\"searchEnabled\",\"searchField\",\"options\",\"placeholder\",\"onchange\"],[[24,[\"selectedProduct\"]],true,true,\"name\",[24,[\"products\"]],\"Αναζήτηση\",[28,\"action\",[[23,0,[]],\"goToProduct\"],null]]],{\"statements\":[[0,\"        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[7,\"div\",true],[10,\"class\",\"search-icon-bg\"],[8],[0,\"\\n            \"],[7,\"img\",true],[10,\"src\",\"/assets/search-black.png\"],[10,\"class\",\"search-icon\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "katanalotis-microsite/templates/components/product-search.hbs"
    }
  });
});