define("katanalotis-microsite/services/cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    flag: false,
    cartOpenedOnce: false,
    init() {
      this._super(...arguments);
      const self = this;
      Ember.set(this, 'items', JSON.parse(localStorage.getItem('cartItems')) || []);
      Ember.set(this, 'merchants', JSON.parse(localStorage.getItem('cartMerchants')));
      var itemsCount = this.items.length > 0 ? this.items.reduce(function (total, x) {
        return {
          count: total.count + x.count
        };
      }).count : 0;
      Ember.set(this, 'itemsCount', itemsCount);
    },
    updateLocalStorage(merchants) {
      var items = this.items;
      if (!this.get('flag')) {
        //items in localStorage are converted to object from ember model class
        //Here they are converted back to model classes
        //It is implemented (first only time that cart is modified) here and not in init beacuse store is not yet populated
        Ember.set(this, 'flag', true);
        const self = this;
        var items = items.map(function (item) {
          return {
            count: item.count,
            product: self.get('store').peekRecord('product', item.product.id)
          };
        });
        Ember.set(this, 'items', items);
      }
      var cartItemsTobeStringified = items.map(function (item) {
        var a = {};
        a.product = item.product.toJSON({
          includeId: true
        });
        a.count = item.count;
        return a;
      });
      localStorage.setItem("cartCount", this.get('itemsCount'));
      localStorage.setItem("cartItems", JSON.stringify(cartItemsTobeStringified));
      merchants ? localStorage.setItem("cartMerchants", JSON.stringify(merchants)) : localStorage.removeItem('cartMerchants');
    },
    itemsCount: 0,
    helperTrigger: 0,
    displayCart: false,
    sortedMerchants: Ember.computed.sort('merchants', function (a, b) {
      // Compare the number of products first
      if (a.products.length !== b.products.length) {
        return b.products.length - a.products.length;
      }
      // If the number of products is the same, compare the total basket prices
      a.sum = 0;
      b.sum = 0;
      a.products.forEach(pro => a.sum += pro.price * pro.count);
      b.products.forEach(pro => b.sum += pro.price * pro.count);
      return a.sum - b.sum;
    }),
    merchantAvailable: Ember.computed('merchants', function () {
      return true; //FIXME
      var merchantAvailable = this.get('merchants').find(merchant => merchant.products.length == this.get('itemsCount'));
      return merchantAvailable ? true : false;
    }),
    openCartIfNeeded() {
      if (this.items.length == 0 || !this.get('cartOpenedOnce')) {
        Ember.set(this, 'cartOpenedOnce', true);
        this.openCart();
      }
    },
    increaseProduct(item, alreadyAddedProduct) {
      // If the item is already in the cart, increase its count in both places it's stored
      Ember.set(alreadyAddedProduct.product, 'count', alreadyAddedProduct.count + 1);
      Ember.set(alreadyAddedProduct, 'count', alreadyAddedProduct.count + 1);

      // Increment product count in merchants object
      var merchants = this.merchants;
      const self = this;
      merchants.forEach(function (merchant) {
        merchant.products.forEach(function (product) {
          if (product.id == item.id) {
            Ember.set(product, 'count', product.count + 1);
            Ember.set(self, 'helperTrigger', self.helperTrigger + 1);
          }
        });
      });

      // Increase total items count
      Ember.set(this, 'itemsCount', this.itemsCount + 1);

      // Notify that the merchants array has changed
      this.notifyPropertyChange('merchants');
      // Update the local storage with the latest state of the cart, including merchants
      this.updateLocalStorage(merchants);
    },
    addProduct(item) {
      var self = this;
      // If the item is not already in the cart, set its count to 1 and add it
      Ember.set(item, 'count', 1);
      console.log({
        item
      });
      this.items.pushObject({
        product: item,
        count: 1
      });

      // Increase total items count
      Ember.set(this, 'itemsCount', this.itemsCount + 1);

      // Initialize merchants array if it doesn't exist
      if (!this.merchants) {
        var merchants = this.items[0].product.prices.map(function (item) {
          let m = self.get('store').peekRecord('merchant', item.merchant_uuid);
          return {
            name: m.display_name,
            uuid: m.id,
            image: m.image,
            expanded: false,
            products: [],
            sum: 0
          };
        });
        Ember.set(this, 'merchants', merchants);
      } else {
        var merchants = this.merchants;
      }

      // Add the new item to each relevant merchant's product list
      var item = this.items[this.items.length - 1];
      var prices = item.product.prices.filter(price => price.price != 0).map(function (item) {
        let m = self.get('store').peekRecord('merchant', item.merchant_uuid);
        return {
          merchant: m,
          price: item.price
        };
      });
      prices.forEach(function (price) {
        var product = {
          name: item.product.name,
          id: item.product.id,
          price: price.price,
          count: 1
        };
        try {
          console.log(price.merchant);
          merchants.find(merchant => merchant.uuid == price.merchant.id).products.pushObject(product);
        } catch (e) {
          merchants.pushObject({
            name: price.merchant.display_name,
            uuid: price.merchant.id,
            image: price.merchant.image,
            expanded: false,
            products: [product],
            sum: 1
          });
        }
      });

      // Notify that the merchants array has changed
      this.notifyPropertyChange('merchants');
      // Update the local storage with the latest state of the cart, including merchants
      this.updateLocalStorage(merchants);
    },
    /**
     * Adds an item to the cart. If the item already exists, increases its count.
     * Updates merchants' product lists and manages the opening state of the cart.
     * @param {Object} item - The item to add to the cart, expected to have an `id`.
     */
    add(item) {
      console.count('cart.add()');
      console.log({
        item
      });
      console.log(this.items);
      console.log(!this.get('cartOpenedOnce'));

      // Check if the cart hasn't been opened yet or is empty, then open it and mark it as opened
      this.openCartIfNeeded();

      // Attempt to find the item in the cart by its ID
      var alreadyAddedProduct = this.items.find(x => x.product.id == item.id);
      console.log({
        alreadyAddedProduct
      });
      if (alreadyAddedProduct) {
        this.increaseProduct(item, alreadyAddedProduct);
      } else {
        this.addProduct(item);
      }
    },
    remove(item) {
      Ember.set(item.product, 'isInBasket', false);
      this.items.removeObject(item);
      Ember.set(this, 'itemsCount', this.itemsCount - item.count);
      var merchants = this.merchants;
      merchants.forEach(function (merchant) {
        merchant.products.forEach(function (product, index) {
          if (product.id == item.product.id) {
            merchant.products.removeObject(product);
          }
        });
      });
      this.notifyPropertyChange('merchants');
      this.updateLocalStorage(merchants);
    },
    removePiece(item) {
      var product = this.items.find(x => x.product.id == item.id);
      if (!product) {
        Ember.set(item, 'count', 0);
        Ember.set(item, 'isInBasket', false);
      } else {
        if (product.count > 1) {
          Ember.set(item, 'count', product.count - 1);
          Ember.set(product, 'count', product.count - 1);
          Ember.set(this, 'itemsCount', this.itemsCount - 1);
          var merchants = this.merchants;
          const self = this;
          merchants.forEach(function (merchant) {
            merchant.products.forEach(function (product) {
              if (product.id == item.id) {
                Ember.set(product, 'count', product.count - 1);
                Ember.set(self, 'helperTrigger', self.helperTrigger - 1);
              }
            });
          });
        } else {
          this.remove(product);
        }
      }
      this.notifyPropertyChange('merchants');
      this.updateLocalStorage(merchants);
    },
    empty() {
      const self = this;
      var merchants = this.items[0].product.prices.map(function (item) {
        item.products = [];
        item.expanded = false;
        return item;
      });
      this.items.forEach(function (item) {
        var storeItem = self.get('store').peekRecord('product', item.product.id);
        Ember.set(storeItem, 'isInBasket', false);
      });
      this.items.clear();
      Ember.set(this, 'itemsCount', 0);
      Ember.set(this, 'merchants', merchants);
      this.updateLocalStorage(merchants);
    },
    toggleMerchant(merchant) {
      var merchants = this.merchants;
      merchants.forEach(function (item) {
        if (item.uuid == merchant.uuid) {
          Ember.set(merchant, 'expanded', !merchant.expanded);
        }
      });
    },
    toggleCart() {
      this.toggleProperty('displayCart');
    },
    openCart() {
      Ember.set(this, 'displayCart', true);
    },
    closeCart() {
      Ember.set(this, 'displayCart', false);
    }
  });
});