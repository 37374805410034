define("katanalotis-microsite/controllers/global-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    products: Ember.computed(function () {
      return this.get('store').peekAll('shop');
    })
    /* fuelstations: Ember.computed(function(){
        return this.get('store').peekAll('fuelstation')
    }) */
  });
});