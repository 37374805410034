define("katanalotis-microsite/controllers/fuels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    resultsService: Ember.inject.service('results-preview'),
    fuelCategories: Ember.computed(function () {
      return this.get('store').peekAll('fuelstation-category');
    }),
    actions: {
      search() {
        Ember.set(this.get('resultsService'), 'fuelType', this.get('selectedCategory'));
        this.transitionToRoute('fuels-results');
      }
    }
  });
});