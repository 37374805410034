define("katanalotis-microsite/routes/charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    successGetHistory(data) {
      const self = this;
      this.get('store').peekAll('price_per_day').content.forEach(function (rec) {
        Ember.run.once(this, function () {
          rec.deleteRecord();
          rec.save();
        });
      }, this);
      var price = data.context.MAPP_PRODUCTS.result.Avg_min_price_per_day;
      $.each(price, function (i, item) {
        self.store.createRecord('price_per_day', item);
      });
    },
    errorGetHistory(data) {
      console.log(data);
    },
    model(params) {
      const store = this.store.peekRecord('product', params.product_id);
      if (!this.store.peekAll('price_per_day').content.length) {
        var warplySDK = this.get('warply_sdk');
        warplySDK.post_context(JSON.stringify({
          "products": {
            "action": "product_history",
            "barcode": store.barcode
          }
        }), this.successGetHistory.bind(this), this.errorGetHistory.bind(this));
      }
      try {
        return store;
      } catch (e) {
        return {};
      }
    },
    actions: {
      didTransition() {
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
      }
    }
  });
});