define("katanalotis-microsite/components/map-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    watchSearchInput: Ember.observer('region', function () {
      let q = this.get('region');
      this.set('regionQuery', q);
    }),
    initMap: function (action) {
      var shopsToRender = this.get('shops');
      var prices = this.get('product').prices;
      var productName = this.get('product').name;
      var avgPrice = this.get('avgPrice');
      var map = new google.maps.Map(document.getElementById('map'), {
        center: {
          lat: 37.983810,
          lng: 23.727539
        },
        zoom: 6,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      self = this;
      var infowindow = null;
      //custom marker
      /* var markers = shopsToRender.map(function(shop, i) {
          var price = prices.find(function(price){
              if(price.merchant.name == shop.merchant || (shop.merchant == "My Market" && price.merchant.name == "My Market ")){
                  return true
              }
          }).price
          if(shop.latitude && shop.longitude){
              return createHTMLMapMarker({
                  latlng: new google.maps.LatLng(shop.latitude, shop.longitude),
                  map: map,
                  html: `<div style="position:absolute;top:-50px;">
                          <div class="marker-wrapper">
                              <img src=${shop.image} class="marker-image">
                              <span class="marker-price">${price}€</span>
                          </div> 
                          <div style="margin:auto;width:0;height:0;border-left:10px solid transparent;border-right:10px solid transparent;border-top: 10px solid #fff;"></div>                            
                          </div>`
                  });
          } else {                
              return createHTMLMapMarker({
                  latlng: new google.maps.LatLng(shop.latitude, shop.longitude),
                  map: map,
                  html: `<div style="display:none;" class="marker-wrapper">
                              <img src=${shop.image} class="marker-image">
                              <span class="marker-price">${price}€</span>
                          </div> `
                  });
          }
      });  */
      var markers = shopsToRender.map(function (shop, i) {
        var price = prices.find(function (price) {
          if (price.merchant.name == shop.merchant || shop.merchant == "My Market" && price.merchant.name == "My Market ") {
            return true;
          }
        }).price;
        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(shop.latitude, shop.longitude),
          map: map
        });
        marker.addListener("click", () => {
          if (infowindow) {
            infowindow.close();
          }
          infowindow = new google.maps.InfoWindow({
            maxWidth: 300,
            content: `
                    <div class="position-relative pt-3 pr-3 info-container">
                        <div class="row mb-3">
                            <div class="col-3">
                                <img class="info-img img-fluid" src="${shop.image}">
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                <span class="info-name">${productName}</span>
                            </div>
                          <img class="info-nav-icon cursor-pointer" src="/assets/nav_icon.png" onClick="getDirections(${shop.latitude},${shop.longitude})">
                        </div>
                        <div class="row">
                          <div class="col-12 d-flex justify-content-between">
                              <div class="d-flex flex-column">                        
                                  <span class="info-aveg-price-text">Μέση τιμή πώλησης</span>
                                  <span class="info-aveg-price">${avgPrice}€</span>
                              </div>
                              <div class="d-flex flex-column">                        
                                  <span class="info-price-text">Τιμή ραφιού</span>
                                  <span class="info-price">${price}€</span>
                              </div>
                          </div>
                      </div>
                    </div>`
          });
          infowindow.open(map, marker);
        });
        return marker;
      });
      var markerCluster = new MarkerClusterer(map, markers, {
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        maxZoom: 100
      });
      var currentPosition;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          currentPosition = pos;
          map.setCenter(pos);
          map.setZoom(13);
        }, () => {
          handleLocationError(true, infoWindow, map.getCenter());
        });
      } else {
        // Browser doesn't support Geolocation
        handleLocationError(false, infoWindow, map.getCenter());
      }
      if (action === 'findRegion') {
        if (markers.length === 0) {
          $('#map').modal('show');
          return;
        }
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < markers.length; i++) {
          bounds.extend(markers[i].getPosition());
        }
        map.fitBounds(bounds);
      } else if (action === 'goToCurrentLocation') {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            let pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            map.panTo(pos);
            map.setZoom(16);
          }, function () {
            console.log('error fetching user\'s position');
          });
        } else {
          console.log('Browser doesn\'t support Geolocation');
        }
      }
    },
    didUpdateAttrs() {
      this.initMap();
    },
    didInsertElement() {
      this.initMap();
    },
    actions: {
      findRegion() {
        this.initMap('findRegion');
      },
      goToCurrentLocation() {
        this.initMap('goToCurrentLocation');
      }
    }
  });
});