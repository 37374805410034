define("katanalotis-microsite/routes/fuels-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    setupController(controller) {
      this._super(...arguments);
      controller.set('trigger', true);
    },
    actions: {
      didTransition() {
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
      }
    }
  });
});