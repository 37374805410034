define("katanalotis-microsite/helpers/energy-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function energyPercentage(params /*, hash*/) {
    const [number] = params;
    const color = number < 0 ? 'red' : 'green';
    const sign = number < 0 ? '↓' : '↑';
    const formattedNumber = Math.abs(number).toLocaleString();
    if (formattedNumber == 0) return null;
    return new Ember.String.htmlSafe(`<span style="color:${color};font-size:14px;">${sign}${formattedNumber}%</span>`);
  });
});