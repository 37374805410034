define("katanalotis-microsite/components/category-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    popNav: Ember.inject.service("pop-nav"),
    subcategories: Ember.computed("category", function () {
      return this.get("store").peekRecord("category", this.get("category").id).sub_categories.sortBy("name");
    }),
    showPopNav: Ember.computed("popNav.showPopNav", function () {
      return this.popNav.get('showPopNav') == this.get('category').id;
    }),
    actions: {
      handleCategoryClick() {
        if (this.get('subcategories').length == 1) {
          //theres only one subcatery
          //go directly to the category page
          this.get('goToCategory')(this.get('category'), this.get('subcategories')[0]);
          return;
        }
        if (this.popNav.get('showPopNav') == this.get('category').id) {
          return this.popNav.set('showPopNav', null); //if clicked again close popup
        }
        this.popNav.set('showPopNav', this.get('category').id);
      },
      handleSubcategoryClick(subcategory) {
        this.get('goToCategory')(this.get('category'), subcategory.uuid);
        this.popNav.set('showPopNav', null);
      }
    }
  });
});