define("katanalotis-microsite/routes/brochure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    async model() {
      let self = this;
      let controller = this.controllerFor('brochure');
      if (!this.get('store').peekAll('brochure').length) {
        await $.getJSON('https://warply.s3.eu-west-1.amazonaws.com/applications/ed840ad545884deeb6c6b699176797ed/basket-retailers/catalog.json', function (data) {
          const retailers = data.retailers;
          $.each(retailers, function (i, item) {
            self.store.createRecord('brochure', item);
          });
          controller.set('baseURL', data.base_url);
        });
        return self.get('store').peekAll('brochure');
      }
    }
  });
});