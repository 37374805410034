define("katanalotis-microsite/routes/rantevou", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    /* 
        successGetRantevou: function(data){
            let self = this;
            var rantevou = data.context.MAPP_PRODUCTS;
            $.each(rantevou, function(i, item) {
                self.store.createRecord('rantevou', {
                    afm: item.afm,
                    type: item.clickInside,
                    date: item.date,
                    hour: item.time,
                    id: i,     
                });
            });
        },
    
        errorGetRantevou: function(){
            console.log("Error");
        },
    
        model(params){
            const rantevou = this.store.peekAll('rantevou', params.rantevou_id);
            this.get('warply_sdk').post_context(
                JSON.stringify({
                    "products": {
                        "action":"click_away",
                        "subaction": "get_appointments"
                    }
                }),this.successGetRantevou.bind(this),this.errorGetRantevou);
                try {
                    return rantevou
                } catch (e) {
                    return {};
                }
        
        },
    
     */

    actions: {
      didTransition() {
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
      }
    }
  });
});