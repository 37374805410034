define("katanalotis-microsite/routes/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model(params) {
      Ember.set(this, 'isAntiseptic', params.products_id == 'antiseptic' ? true : false);
    },
    /*results: service('results-preview'),*/
    setupController(controller, model) {
      this._super(...arguments);
      controller.set('selectedCategory', null);
      controller.set('selectedSubCategory', null);
    },
    actions: {
      didTransition() {
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
      }
    }
  });
});