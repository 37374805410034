define("katanalotis-microsite/templates/components/products-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "in+xlsq/",
    "block": "{\"symbols\":[\"filter\",\"index\",\"filter\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n\\n  \"],[7,\"p\",true],[10,\"class\",\"h5 nova-sb hide-mobile\"],[8],[0,\"Αλυσίδα\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isProducts\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"marketsShowAll\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"show-all-scroll-container\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"markets\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n\"],[4,\"radio-button\",null,[[\"value\",\"groupValue\"],[[23,3,[\"name\"]],[24,[\"checkedMarket\"]]]],{\"statements\":[[0,\"                \"],[7,\"span\",true],[8],[1,[23,3,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[3,4]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"markets\"]]],null,{\"statements\":[[4,\"if\",[[28,\"lt\",[[23,2,[]],5],null]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n\"],[4,\"radio-button\",null,[[\"value\",\"groupValue\"],[[23,1,[\"name\"]],[24,[\"checkedMarket\"]]]],{\"statements\":[[0,\"                \"],[7,\"span\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"      \"],[7,\"p\",false],[12,\"class\",\"font-xs nova-sb cursor-pointer\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"marketsShowAll\"]]],null],true]],[8],[0,\"+ Εμφάνιση Περισσότερων\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "katanalotis-microsite/templates/components/products-filter.hbs"
    }
  });
});