define("katanalotis-microsite/routes/fuels-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    resultsService: Ember.inject.service('results-preview'),
    model(params) {
      const controller = this.controllerFor('fuels-results');
      Ember.set(this, 'date', params.date);
      Ember.set(this, 'merchant', params.merchant);
      Ember.set(controller, 'date', params.date);
      Ember.set(controller, 'merchant', params.merchant);
      return this.get('store').peekAll('fuelstation');
    },
    actions: {
      didTransition() {
        if (this.get('date') || this.get('merchant')) {
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
          Ember.set(this.get('controller'), 'fuelType', this.get('store').peekAll('fuelstation-category').find(x => x.id == this.get('merchant')));
        } else if (!this.get('resultsService').fuelType) {
          this.transitionTo('fuels');
        } else {
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
          Ember.set(this.get('controller'), 'fuelType', this.get('resultsService').fuelType);
        }
      }
    }
  });
});