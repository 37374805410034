define("katanalotis-microsite/routes/energy-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    async model() {
      let self = this;
      let controller = this.controllerFor('energy-basket');
      if (!this.get('store').peekAll('retailer').length) {
        await $.getJSON('https://warply.s3.eu-west-1.amazonaws.com/applications/ed840ad545884deeb6c6b699176797ed/basket-retailers/energy.json', function (data) {
          var operators = data.operators;
          let operatorNames = [];
          let products = [];
          console.log(data);
          $.each(operators, function (i, operator) {
            operatorNames.push(operator.name);
            $.each(operator.contracts, function (y, contract) {
              const product = {
                operator: operator.name,
                productLogo: operator.logo,
                productName: contract.name,
                base: contract.base,
                baseDiscountCondition: contract.base_discount_condition,
                unit_value: contract.unit_value,
                unitValueDiscountCondition: contract.unit_value_discount_condition,
                contract_value: contract.contract_value.toFixed(2),
                perc: contract.perc.toFixed(2),
                sparkline: contract.past,
                grant: contract.grant,
                max: contract.max,
                min: contract.min,
                type: contract.type
              };
              products.push(product);
              self.store.createRecord('energyProduct', product);
            });
          });
          controller.set('baseURL', data.base_url);
          controller.set('from', data.from);
          controller.set('contract_month_title', data.contract_month_title);
          controller.set('to', data.to);
          controller.set('header', data.header);
          controller.set('operatorNames', operatorNames.sort());
          controller.set('products', products);
        });
        return self.get('store').peekAll('energyProduct');
      }
    }
  });
});