define("katanalotis-microsite/components/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    companiesShowAll: false,
    countiesShowAll: false,
    actions: {
      clearFilter(type) {
        var filter = this.get(type);
        filter.forEach(x => Ember.set(x, 'checked', false));
      }
    }
  });
});