define("katanalotis-microsite/components/products-filter", ["exports", "ember-resolver"], function (_exports, _emberResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    marketsShowAll: false,
    countiesShowAll: false,
    actions: {
      clearFilter() {
        Ember.set(this, 'checkedMarket', null);
      }
    }
  });
});