define("katanalotis-microsite/router", ["exports", "katanalotis-microsite/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('products', {
      path: '/products/:products_id'
    });
    this.route('useful');
    this.route('product', {
      path: '/product/:product_id'
    });
    this.route('charts', {
      path: '/charts/:product_id'
    });
    this.route('shops', {
      path: '/shops/:product_id'
    });
    this.route('product-preview');
    this.route('contact');
    // this.route('fuels');
    // this.route('click-away', {path:':type'});
    this.route('rantevouModal');
    this.route('metakinisiModal');
    this.route('katastimaModal');
    this.route('rantevou');
    // this.route('fuels-results');
    this.route('useful-differences');
    this.route('useful-rights');
    this.route('global-map');
    // this.route('fuels-map', {path:':fuels-map'});
    this.route('informative');
    this.route('terms');
    this.route('policy');
    this.route('faq');
    this.route('index-news', {
      path: '/index-news/:article_id'
    });
    this.route('householdBasket');
    /* this.route('santasBasket'); */
    this.route('brochure');
    this.route('easterBasket');
    // this.route('energyBasket');
    this.route('freshBasket');
    this.route('studentsBasket');
  });
});