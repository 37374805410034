define("katanalotis-microsite/templates/components/sparkline-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tsWhK1xW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"id\"],[10,\"style\",\"width:100%;\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "katanalotis-microsite/templates/components/sparkline-chart.hbs"
    }
  });
});