define("katanalotis-microsite/components/newsletter-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      handleFormSubmission() {
        const email = this.get('email');
        console.log({
          email
        });

        // Reset previous error message
        const errorMessageElement = document.querySelector('.newsletter-error');
        errorMessageElement.textContent = '';

        // Check if email is empty
        if (!email) {
          errorMessageElement.textContent = 'Το e-mail είναι απαραίτητο'; // Display error message
          errorMessageElement.style.color = 'red';
          // TODO: Update UX to show red error for empty email
          return;
        }

        // Check if email format is valid using regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          errorMessageElement.textContent = 'Το e-mail ειναι λάθος!'; // Display error message
          errorMessageElement.style.color = 'red';
          // TODO: Update UX to show red error for invalid email format
          return;
        }
        console.log("Email for submissions:", email);
        var warplySDK = this.get("warply_sdk");
        var url = "https://engage.warp.ly/api/subscriptions";
        var data = {
          email: email,
          app_uuid: warplySDK.get("app_uuid"),
          action: "subscribe",
          web_id: warplySDK.get("web_id"),
          optin: {
            newsletter: true,
            segmentation: true
          }
        };
        jQuery.ajax(url, {
          type: "POST",
          async: true,
          data: JSON.stringify(data),
          contentType: "application/json",
          success: function (response) {
            if (response.hasOwnProperty("status") && response.status == "1") {
              console.log('Successful Newsletter subscription'); // Log success
              errorMessageElement.textContent = 'Επιτυχής Εγγραφή';
              errorMessageElement.style.color = 'white'; // Set text color to white
              // TODO: Update UX to show success message
            } else if (response.hasOwnProperty("status") && response.status == "3") {
              console.log('You have already subscribed '); // Log message
              // TODO: Update UX to show message for already subscribed
              errorMessageElement.textContent = 'Έχετε ήδη εγγραφεί';
              errorMessageElement.style.color = 'white'; // Set text color to white
            } else {
              console.error('Subscription failed'); // Log error
              errorMessageElement.textContent = 'Το Email δεν έγινε δεκτό'; // Display error message
              errorMessageElement.style.color = 'red'; // Set text color to red
              // TODO: Update UX to show error message for subscription failed
            }
          }
        });

        // Example: Log the email to the console
        console.log("Submitted email:", email);
      }
    }
  });
});