define("katanalotis-microsite/routes/household-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    async model() {
      let self = this;
      let controller = this.controllerFor('household-basket');
      let date = new Date();
      let formattedDate = ('0' + date.getDate()).slice(-2) + '_' + ('0' + (date.getMonth() + 1)).slice(-2) + '_' + date.getFullYear();
      if (!this.get('store').peekAll('retailer').length) {
        await $.getJSON('https://warply.s3.eu-west-1.amazonaws.com/applications/ed840ad545884deeb6c6b699176797ed/basket-retailers/basket.json', function (data) {
          var retailers = data.retailers;
          $.each(retailers, function (i, item) {
            if (i == 'ab') {
              item.active = true;
            }
            self.store.createRecord('retailer', item);
          });
          controller.set('baseURL', data.base_url);
          controller.set('from', data.from);
          controller.set('to', data.to);
          controller.set('variance', data.variance);
        });
        return self.get('store').peekAll('retailer');
      }
    }
  });
});