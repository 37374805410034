define("katanalotis-microsite/templates/components/image-with-fallback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DTAmSR4Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"src\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",true],[11,\"src\",[22,\"src\"]],[11,\"alt\",[22,\"alt\"]],[11,\"class\",[29,[[22,\"class\"]]]],[11,\"onerror\",[28,\"action\",[[23,0,[]],\"handleImageError\"],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "katanalotis-microsite/templates/components/image-with-fallback.hbs"
    }
  });
});