define("katanalotis-microsite/controllers/products", ["exports", "ember-resolver"], function (_exports, _emberResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    categories: Ember.computed(function () {
      return this.get("store").peekAll("category").sortBy("name");
    }),
    subCategories: Ember.computed("selectedCategory", function () {
      return this.get("selectedCategory") ? this.get("store").peekRecord("category", this.get("selectedCategory").id).sub_categories.sortBy("name") : null;
    }),
    changeOutline: Ember.observer("selectedSubCategory.name", function () {
      if (this.get("selectedSubCategory")) {
        $("#enabled").addClass("noOutline");
      }
    }),
    results: Ember.inject.service("results-preview"),
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    formatDate(date) {
      return [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("/");
    },
    date: Ember.computed("productsCount", function () {
      if (parseInt(this.get("productsCount")) > 0) {
        var product = this.get("filteredProducts")[0];
        var dates = product.prices.map(x => {
          var date = JSON.parse(x.extra_fields).date;
          date = date.split("/").reverse().join("/");
          return new Date(date).getTime();
        });
        var maxDate = Math.max(...dates);
        maxDate = this.formatDate(new Date(maxDate));
        return maxDate;
      } else {
        return null;
      }
    }),
    actions: {
      goToProducts() {
        if (!this.get("selectedCategory")) {
          Ember.set(this, "categoryError", true);
          Ember.set(this, "subCategoryError", true);
          return;
        } else if (!this.get("selectedSubCategory")) {
          Ember.set(this, "categoryError", false);
          Ember.set(this, "subCategoryError", true);
          return;
        }
        this.transitionToRoute("product-preview");
        let results = Ember.set(this, "results.category", this.get("selectedCategory"));
        let results1 = Ember.set(this, "results.subCategory", this.get("selectedSubCategory"));
        console.log(this.get("results").category);
      }
    }
  });
});