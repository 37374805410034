define("katanalotis-microsite/components/cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didRender() {
      this._super(...arguments);
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    actions: {
      emptyCart() {
        this.cart.empty();
      },
      removeFromCart(item) {
        this.cart.remove(item);
      },
      addPiece(item) {
        this.cart.add(item.product);
      },
      removePiece(item) {
        this.cart.removePiece(item.product);
      },
      toggleMerchant(merchant) {
        this.cart.toggleMerchant(merchant);
      },
      toggleCart() {
        this.cart.toggleCart();
      }
    }
  });
});