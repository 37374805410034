define("katanalotis-microsite/components/fuels-map-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    watchSearchInput: Ember.observer('region', function () {
      let q = this.get('region');
      this.set('regionQuery', q);
    }),
    initMap: function (action) {
      var stationsToRender = this.get('fuelstations');
      var code = this.get('storeId')['fuels-map'];
      let store = stationsToRender.find(x => x.store_id == code);
      const fuelType = this.get('fuelType');
      var map = new google.maps.Map(document.getElementById('map'), {
        center: {
          lat: 37.983810,
          lng: 23.727539
        },
        zoom: 6,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      self = this;
      var infowindow = null;
      var fuelsMarkers = stationsToRender.map(function (shop, i) {
        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(shop.latitude, shop.longitude),
          map: map
        });
        marker.addListener("click", () => {
          if (infowindow) {
            infowindow.close();
          }
          infowindow = new google.maps.InfoWindow({
            maxWidth: 300,
            content: `
                    <div class="position-relative pt-3 pr-3 info-container">
                        <div class="row mb-3">
                            <div class="col-3">
                                <img class="info-img img-fluid" src="/assets/fuelstation.png">
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                <span class="info-name">${shop.address}</span>
                            </div>
                          <img class="info-nav-icon cursor-pointer" src="/assets/nav_icon.png" onClick="getDirections(${shop.latitude},${shop.longitude})">
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-between">
                                <div class="d-flex flex-column">  
                                    <span class="info-price-text">Είδος καυσίμου</span>                          
                                    <span class="info-fuel-type">${fuelType}</span>
                                </div>
                                <div class="d-flex flex-column">      
                                    <span class="info-price-text">Μέση τιμή</span>               
                                    <span class="info-fuel-price">${shop.price}€</span>
                                </div>
                            </div>
                        </div>
                    </div>`
          });
          infowindow.open(map, marker);
        });
        return marker;
      });
      var markerCluster = new MarkerClusterer(map, fuelsMarkers, {
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
      });
      var currentPosition;
      // if (navigator.geolocation) {
      //     navigator.geolocation.getCurrentPosition(

      if (store) {
        try {
          const pos = {
            lat: store.latitude,
            lng: store.longitude
          };
          currentPosition = pos;
          map.setCenter(pos);
          map.setZoom(13);
          () => {
            handleLocationError(true, infoWindow, map.getCenter());
          };
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          const pos = {
            lat: fuelsMarkers[0].position.lat(),
            lng: fuelsMarkers[0].position.lng()
          };
          currentPosition = pos;
          map.setCenter(pos);
          map.setZoom(10);
          () => {
            handleLocationError(true, infoWindow, map.getCenter());
          };
        } catch (e) {
          console.log(e);
        }
      }
      // );
      // } else {
      //     // Browser doesn't support Geolocation
      //     handleLocationError(false, infoWindow, map.getCenter());
      // }

      if (action === 'findRegion') {
        if (markers.length === 0) {
          $('#map').modal('show');
          return;
        }
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < markers.length; i++) {
          bounds.extend(markers[i].getPosition());
        }
        map.fitBounds(bounds);
      } else if (action === 'goToCurrentLocation') {
        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition(function(position) {
        let pos = {
          lat: store.latitude,
          lng: store.longitude
        };
        map.panTo(pos);
        map.setZoom(16);
        // }, function() {
        //     console.log('error fetching user\'s position');
        // });
        // } else {
        //     console.log('Browser doesn\'t support Geolocation');
        // }
      }
    },
    didUpdateAttrs() {
      this.initMap();
    },
    didInsertElement() {
      this.initMap();
    },
    actions: {
      findRegion() {
        this.initMap('findRegion');
      },
      goToCurrentLocation() {
        this.initMap('goToCurrentLocation');
      }
    }
  });
});