define("katanalotis-microsite/controllers/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    actions: {
      submit() {
        const firstName = this.get('firstName');
        const lastName = this.get('lastName');
        const email = this.get('email');
        const msisdn = this.get('msisdn');
        const message = this.get('message');
        var inputError = false;
        if (!firstName) {
          $('#firstName').addClass('error-input');
          inputError = true;
        } else {
          $('#firstName').removeClass('error-input');
        }
        if (!lastName) {
          $('#lastName').addClass('error-input');
          inputError = true;
        } else {
          $('#lastName').removeClass('error-input');
        }
        if (!email || email && !this.validateEmail(email)) {
          $('#email').addClass('error-input');
          inputError = true;
        } else {
          $('#email').removeClass('error-input');
        }
        if (!msisdn || msisdn && (msisdn.length != 10 || !parseInt(msisdn))) {
          $('#msisdn').addClass('error-input');
          inputError = true;
        } else {
          $('#msisdn').removeClass('error-input');
        }
        if (!message) {
          $('#message').addClass('error-input');
          inputError = true;
        } else {
          $('#message').removeClass('error-input');
        }
        if (inputError) {
          return;
        }
        var warplySDK = this.get('warply_sdk');
        const self = this;
        warplySDK.post_context(JSON.stringify({
          "contact": {
            "action": "addContact",
            'first_name': firstName,
            'last_name': lastName,
            'email': email,
            'msisdn': msisdn,
            'message': message
          }
        }), function (response) {
          Ember.set(self, 'responseText', 'H επικοινωνία σας έχει αποσταλεί επιτυχώς.');
          $('#contactModal').modal('show');
          $('#contactModal').on('hidden.bs.modal', function () {
            Ember.set(self, 'firstName', '');
            Ember.set(self, 'lastName', '');
            Ember.set(self, 'email', '');
            Ember.set(self, 'msisdn', '');
            Ember.set(self, 'message', '');
          });
        }, function (response) {
          Ember.set(self, 'responseText', Ember.String.htmlSafe('H επικοινωνία σας δεν έχει αποσταλεί. <br>Παρακαλούμε προσπαθήστε ξανά.'));
          $('#contactModal').modal('show');
        });
      }
    }
  });
});