define("katanalotis-microsite/helpers/price-sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function priceSum(params /*, hash*/) {
    var result = params[0].map(product => product.price * product.count).reduce((a, b) => a + b, 0);
    return result % 1 == 0 ? result : result.toFixed(2);
  });
});