define("katanalotis-microsite/utils/filtering", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sortGreekFirst = sortGreekFirst;
  // filtering.js

  function sortGreekFirst(markets) {
    return markets.sort((a, b) => {
      // Check if both strings are Greek or not
      const aIsGreek = /^[\u0370-\u03FF]+$/.test(a);
      const bIsGreek = /^[\u0370-\u03FF]+$/.test(b);

      // If both strings are Greek or both are not Greek, sort them based on the current locale
      if (aIsGreek === bIsGreek) {
        return a.localeCompare(b, navigator.language, {
          sensitivity: 'base'
        });
      }

      // If one string is Greek and the other is not, sort the Greek string first
      if (aIsGreek) {
        return -1;
      } else {
        return 1;
      }
    });
  }
});