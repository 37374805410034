define("katanalotis-microsite/controllers/charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    product: Ember.computed('model', function () {
      return this.get('model');
    }),
    merchantsImagesPrefix: Ember.computed('product', function () {
      var image = this.get('store').peekAll('product').get('firstObject').prices[0].merchant.image;
      return image.slice(0, image.lastIndexOf('/') + 1);
    }),
    merchantsImages: Ember.computed('product', function () {
      return this.get('store').peekAll('product').get('firstObject').prices.map(price => price.merchant.image);
    }),
    minMerchantsImages: Ember.computed('merchantsImages', function () {
      const self = this;
      var minMarkets = this.get('pricePerDay').slice(-6).map(price => price.Min_Markets);
      return minMarkets.map(market => market.map(item => self.merchantsImages.find(x => x.includes(item))));
    }),
    colNum: Ember.computed('minMerchantsImages', function () {
      return parseInt(12 / this.get('minMerchantsImages').length);
    }),
    colOffset: Ember.computed('minMerchantsImages', function () {
      return parseInt(12 % this.get('minMerchantsImages').length);
    }),
    pricePerDay: Ember.computed('product', function () {
      return this.get('store').peekAll('price-per-day').slice(-6);
    }),
    pricePerDayDates: Ember.computed('pricePerDay', function () {
      return this.get('pricePerDay').map(item => item.Day_Month);
    }),
    pricePerDayFullDates: Ember.computed('pricePerDay', function () {
      var fullDate = this.get('pricePerDay').map(item => item.Full_Date).map(function (date) {
        const greekDates = ['Δ', 'Τ', 'Τ', 'Π', 'Π', 'Σ', 'Κ'];
        const engDates = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        return greekDates[engDates.findIndex(engDate => engDate == date)];
      });
      return fullDate;
    }),
    pricePerDayMin: Ember.computed('pricePerDay', function () {
      return this.get('pricePerDay').map(item => item.Min_Price);
    }),
    minMax: Ember.computed('pricePerDayMin', function () {
      return Math.max(...this.get('pricePerDayMin'));
    }),
    minMin: Ember.computed('pricePerDayMin', function () {
      return Math.min(...this.get('pricePerDayMin'));
    }),
    pricePerDayAvg: Ember.computed('pricePerDay', function () {
      return this.get('pricePerDay').map(item => item.Avg_Price);
    }),
    avgMax: Ember.computed('pricePerDayAvg', function () {
      return Math.max(...this.get('pricePerDayAvg'));
    }),
    avgMin: Ember.computed('pricePerDayAvg', function () {
      return Math.min(...this.get('pricePerDayAvg'));
    }),
    date: Ember.computed(function () {
      return JSON.parse(this.get('product').prices[0].extra_fields).date;
    }),
    chartOptionsMin: Ember.computed('pricePerDayDates', function () {
      const self = this;
      return {
        chart: {
          type: 'area'
        },
        title: {
          text: 'ΕΛΑΧΙΣΤΗ ΤΙΜΗ'
        },
        xAxis: {
          categories: this.get('pricePerDayDates')
        },
        yAxis: {
          labels: {
            formatter: function () {
              return parseFloat(this.axis.defaultLabelFormatter.call(this)).toFixed(2) + '€';
            }
          },
          title: {
            text: ''
          },
          max: this.get('minMax') + 0.02,
          min: this.get('minMin') - 0.02
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          valueSuffix: '€'
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [[0, '#D987D9'], [1, Highcharts.color('#D987D9').setOpacity(0).get('rgba')]]
            }
          },
          series: {
            color: '#D987D9'
          }
        }
      };
    }),
    chartDataMin: Ember.computed('pricePerDayDates', function () {
      const self = this;
      return [{
        name: '',
        data: this.get('pricePerDayMin')
      }];
    }),
    chartOptionsAvg: Ember.computed('pricePerDayDates', function () {
      const self = this;
      return {
        chart: {
          type: 'areaspline'
        },
        title: {
          text: 'ΜΕΣΗ ΤΙΜΗ'
        },
        xAxis: {
          categories: this.get('pricePerDayDates')
        },
        yAxis: {
          labels: {
            formatter: function () {
              return parseFloat(this.axis.defaultLabelFormatter.call(this)).toFixed(2) + '€';
            }
          },
          title: {
            text: ''
          },
          max: this.get('avgMax') + 0.02,
          min: this.get('avgMin') - 0.02
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          valueSuffix: '€'
        },
        plotOptions: {
          areaspline: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [[0, '#29D0BC'], [1, Highcharts.color('#29D0BC').setOpacity(0).get('rgba')]]
            }
          },
          series: {
            color: '#29D0BC'
          }
        }
      };
    }),
    chartDataAvg: Ember.computed('pricePerDayDates', function () {
      const self = this;
      return [{
        name: '',
        data: this.get('pricePerDayAvg')
      }];
    }),
    chartOptionsMerchant: Ember.computed('pricePerDayDates', function () {
      const self = this;
      return {
        chart: {
          type: 'column'
        },
        title: {
          text: 'ΑΛΥΣΙΔΑ ΜΕ ΕΛΑΧΙΣΤΗ ΤΙΜΗ'
        },
        xAxis: {
          categories: this.get('pricePerDayFullDates')
        },
        yAxis: {
          labels: {
            formatter: function () {
              return parseFloat(this.axis.defaultLabelFormatter.call(this)).toFixed(2) + '€';
            }
          },
          title: {
            text: ''
          },
          max: this.get('minMax') + 0.02,
          min: this.get('minMin') - 0.02
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          valueSuffix: '€'
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        }
      };
    }),
    chartDataMerchant: Ember.computed('pricePerDayDates', function () {
      const self = this;
      return [{
        name: '',
        data: this.get('pricePerDayMin'),
        color: '#E3E9F6'
      }];
    })
  });
});