define("katanalotis-microsite/controllers/shops", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    filteredShopsByRegion: Ember.computed('regionQuery', 'filteredShops', function () {
      let query = this.get('regionQuery');
      if (!query) {
        return this.get('filteredShops');
      } else {
        return this.get('filteredShops').filter(shop => shop.region.includes(query.toLocaleUpperCase()));
      }
    }),
    product: Ember.computed('model', function () {
      return this.get('model');
    }),
    merchants: Ember.computed('model', function () {
      var merchants = this.get('model').prices.filter(price => price.price > 0).map(price => price.merchant.name);
      return merchants.map(function (merchant) {
        if (merchant == 'My Market ') {
          return 'My Market';
        }
        return merchant;
      });
    }),
    filteredShops: Ember.computed('model', function () {
      return this.store.peekAll('shop').filter(shop => this.get('merchants').includes(shop.merchant));
    }),
    avgPrice: Ember.computed('pricePerDay', function () {
      var pricePerDay = this.get('store').peekAll('price-per-day').slice(-6);
      return pricePerDay[pricePerDay.length - 1].Avg_Price;
    })
  });
});