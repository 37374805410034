define("katanalotis-microsite/helpers/decimals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function lowestPrice(params /*, hash*/) {
    return params[0].toFixed(2);
  });
});