define("katanalotis-microsite/components/global-map-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    watchSearchInput: Ember.observer('region', function () {
      let q = this.get('region');
      this.set('regionQuery', q);
    }),
    initMap: function (action) {
      var shopsToRender = this.get('products');
      var stationsToRender = this.get('fuelstations');
      var map = new google.maps.Map(document.getElementById('map'), {
        center: {
          lat: 37.983810,
          lng: 23.727539
        },
        zoom: 6,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      self = this;
      var infowindow = null;
      var shopMarkers = shopsToRender.map(function (shop, i) {
        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(shop.latitude, shop.longitude),
          map: map
        });
        marker.addListener("click", () => {
          if (infowindow) {
            infowindow.close();
          }
          infowindow = new google.maps.InfoWindow({
            maxWidth: 300,
            content: `
                    <div class="position-relative pt-3 pr-3 info-container">
                        <div class="row mb-3">
                            <div class="col-3">
                                <img class="info-img img-fluid" src="${shop.image}">
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                <span class="info-name">${shop.address}</span>
                            </div>
                          <img class="info-nav-icon cursor-pointer" src="/assets/nav_icon.png" onClick="getDirections(${shop.latitude},${shop.longitude})">
                        </div>
                    </div>`
          });
          infowindow.open(map, marker);
        });
        return marker;
      });
      var fuelMarkers = stationsToRender.map(function (shop, i) {
        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(shop.latitude, shop.longitude),
          map: map
        });
        marker.addListener("click", () => {
          if (infowindow) {
            infowindow.close();
          }
          infowindow = new google.maps.InfoWindow({
            maxWidth: 300,
            content: `
                    <div class="position-relative pt-3 pr-3 info-container">
                        <div class="row mb-3">
                            <div class="col-3">
                                <img class="info-img img-fluid" src="/assets/fuelstation.png">
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                <span class="info-name">${shop.address}</span>
                            </div>
                          <img class="info-nav-icon cursor-pointer" src="/assets/nav_icon.png" onClick="getDirections(${shop.latitude},${shop.longitude})">
                        </div>
                    </div>`
          });
          infowindow.open(map, marker);
        });
        return marker;
      });
      var markerCluster = new MarkerClusterer(map, shopMarkers.concat(fuelMarkers), {
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
      });
      var currentPosition;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          currentPosition = pos;
          map.setCenter(pos);
          map.setZoom(13);
        }, () => {
          handleLocationError(true, infoWindow, map.getCenter());
        });
      } else {
        // Browser doesn't support Geolocation
        handleLocationError(false, infoWindow, map.getCenter());
      }
      if (action === 'findRegion') {
        if (markers.length === 0) {
          $('#map').modal('show');
          return;
        }
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < markers.length; i++) {
          bounds.extend(markers[i].getPosition());
        }
        map.fitBounds(bounds);
      } else if (action === 'goToCurrentLocation') {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            let pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            map.panTo(pos);
            map.setZoom(16);
          }, function () {
            console.log('error fetching user\'s position');
          });
        } else {
          console.log('Browser doesn\'t support Geolocation');
        }
      }
    },
    didUpdateAttrs() {
      this.initMap();
    },
    didInsertElement() {
      this.initMap();
    },
    actions: {
      findRegion() {
        this.initMap('findRegion');
      },
      goToCurrentLocation() {
        this.initMap('goToCurrentLocation');
      }
    }
  });
});