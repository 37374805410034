define("katanalotis-microsite/models/product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    barcode: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    sub_category: _emberData.default.attr('string'),
    sub_sub_category: _emberData.default.attr('string'),
    supplier: _emberData.default.attr('string'),
    prices: _emberData.default.attr(),
    promo: _emberData.default.attr('boolean'),
    monimi_meiosi: _emberData.default.attr('boolean'),
    extra_fields: _emberData.default.attr('string')
  });
});