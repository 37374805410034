define("katanalotis-microsite/controllers/rantevou", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    incomplete: Ember.computed('model', function () {
      let model = this.model;
      return model.filter(item => new Date(item.time) > new Date());
    }),
    complete: Ember.computed('model', function () {
      let model = this.model;
      return model.filter(item => new Date(item.time) <= new Date());
    }),
    actions: {
      submit() {
        try {
          //   document.getElementById("spinner-back").classList.add("show");
          //   document.getElementById("spinner-front").classList.add("show");

          var unsetFields = false;
          var data = {
            name: $("#name").val(),
            vat: $("#vat").val(),
            address: $("#address").val(),
            email: $("#email").val()
          };
          for (var x in data) {
            $("#" + x).removeClass('error');
            if (!data[x]) {
              $("#" + x).addClass('error');
              unsetFields = true;
            }
          }
          if (unsetFields) {
            // document.getElementById("spinner-back").classList.remove("show");
            // document.getElementById("spinner-front").classList.remove("show");
            return;
          }
          $.ajax({
            'url': ' https://engage-stage.warp.ly/dashboard/api/merchants/click_away',
            'method': 'POST',
            'data': JSON.stringify(data),
            'contentType': "application/json",
            'success': function (response) {
              //   document.getElementById("spinner-back").classList.remove("show");
              //   document.getElementById("spinner-front").classList.remove("show");
              for (var x in data) {
                $("#" + x).val('');
              }
              if (response.status == 1) {
                $('#responseMessage').text('Η εγγραφή σας καταχωρήθηκε επιτυχώς!');
              } else if (response.status == 3) {
                $('#responseMessage').text('Ο συγκεκριμένος χρήστης έχει ήδη εγγραφεί.');
              } else {
                $('#responseMessage').text('Κάτι πήγε στραβά, παρακαλώ δοκιμάστε αργοτερά.');
              }
              $('#exampleModal').modal('hide');
              $('#responseModal').modal('show');
            },
            'error': function (response) {
              //   document.getElementById("spinner-back").classList.remove("show");
              //   document.getElementById("spinner-front").classList.remove("show");
              $('#responseMessage').text('Κάτι πήγε στραβά, παρακαλώ δοκιμάστε αργοτερά.');
              $('#exampleModal').modal('hide');
              $('#responseModal').modal('show');
            }
          });
        } catch (error) {
          console.log(error);
          //   document.getElementById("spinner-back").classList.remove("show");
          //   document.getElementById("spinner-front").classList.remove("show");
          $('#responseMessage').text('Κάτι πήγε στραβά, παρακαλώ δοκιμάστε αργοτερά.');
          $('#exampleModal').modal('hide');
          $('#responseModal').modal('show');
        }
      }
    }
  });
});