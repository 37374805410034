define("katanalotis-microsite/controllers/energy-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    selectedPage: 1,
    itemsPerPage: 30,
    selectedTypes: [],
    selectedConsumption: 310.83,
    selectedSort: {
      name: 'Αύξουσα',
      fn: function (a, b) {
        return a.unit_value - b.unit_value;
      }
    },
    sortOptions: [{
      name: 'Αύξουσα',
      fn: function (a, b) {
        return a.unit_value - b.unit_value;
      }
    }, {
      name: 'Ανά πάροχο',
      fn: function (a, b) {
        return a.operator.localeCompare(b.operator);
      }
    }],
    typeOptions: [{
      "name": "Σταθερό Τιμολόγιο",
      "value": "stathero"
    }, {
      "name": "Κυμαινόμενο Τιμολόγιο",
      "value": "keimenomeno"
    }, {
      "name": "Ειδικό Τιμολόγιο",
      "value": "eidiko"
    }, {
      "name": "Δυναμικό Τιμολόγιο",
      "value": "dynamiko"
    }],
    selectedProvider: null,
    // filtering: Ember.observer('selectedType', function() {
    //     console.log('hello world')
    // }),
    productsPaginated: Ember.computed('products', 'selectedSort', 'selectedTypes', 'products.@each', 'selectedPage', function () {
      const types = this.get('selectedTypes').map(c => {
        return c.value;
      });
      if (this.get('products')) {
        var indexStart = (this.get('selectedPage') - 1) * this.get('itemsPerPage');
        var products;
        if (types.length > 0) {
          products = this.get('products').filter(a => {
            return types.includes(a.type);
          });
        } else {
          products = this.get('products');
        }
        return products.sort(this.get('selectedSort').fn).slice(indexStart, this.get('itemsPerPage') + indexStart);
      }
    }),
    totalPages() {
      if (this.get('products')) {
        return Math.ceil(this.get('products').length / this.get('itemsPerPage'));
      } else {
        return 0;
      }
    },
    totalProducts: Ember.computed('productsPaginated', function () {
      const types = this.get('selectedTypes').map(c => {
        return c.value;
      });
      if (this.get('products')) {
        var indexStart = (this.get('selectedPage') - 1) * this.get('itemsPerPage');
        var products;
        if (types.length > 0) {
          products = this.get('products').filter(a => {
            return types.includes(a.type);
          });
        } else {
          products = this.get('products');
        }
        return products.length;
      }
    }),
    lastPage: Ember.computed('products', 'products.@each', 'selectedPage', function () {
      if (this.get('products')) {
        return this.totalPages() == 1 ? null : this.totalPages();
      }
    }),
    midPages: Ember.computed('products', 'products.@each', 'selectedPage', function () {
      if (this.get('products')) {
        var selectedPage = this.get('selectedPage');
        var totalPages = this.totalPages();
        var lastPage = this.get('lastPage');
        if (totalPages > 3) {
          var midPages = [];
          if (selectedPage - 1 > 1) {
            midPages = [selectedPage];
            midPages.unshift(selectedPage - 1);
          } else if (selectedPage - 1 > 0) {
            midPages = [selectedPage];
          }
          if (lastPage - selectedPage > 1) {
            midPages.push(selectedPage + 1);
          } else if (lastPage - selectedPage > 0) {
            midPages = [selectedPage];
          }
          if (selectedPage - 1 > 1) {
            Ember.set(this, 'showPrevDots', true);
          } else {
            Ember.set(this, 'showPrevDots', false);
          }
          if (lastPage - selectedPage > 1) {
            Ember.set(this, 'showAfterDots', true);
          } else {
            Ember.set(this, 'showAfterDots', false);
          }
          return midPages;
        } else if (totalPages == 3) {
          return [2];
        }
        return null;
      }
    }),
    actions: {
      decrementPage() {
        if (this.get('selectedPage') != 1) {
          Ember.set(this, 'selectedPage', this.get('selectedPage') - 1);
        }
      },
      incrementPage() {
        if (this.get('selectedPage') != this.totalPages()) {
          Ember.set(this, 'selectedPage', this.get('selectedPage') + 1);
        }
      },
      selectPage(page) {
        Ember.set(this, 'selectedPage', page);
      }
    }
  });
});