define("katanalotis-microsite/routes/index-news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model(params) {
      return this.store.peekRecord('article', params.article_id);
    },
    actions: {
      didTransition() {
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
      }
    }
  });
});