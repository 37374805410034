define("katanalotis-microsite/helpers/set-chart-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function setChartOptions(params /*, hash*/) {
    let variance = params[0];
    let type = params[1];
    let category;
    let categories;
    if (params[2]) {
      category = params[2] - 1;
      categories = variance[category].map(item => item.Product + ' ' + item.Day_Month);
    } else {
      categories = variance.map(item => item.Product + ' ' + item.Day_Month);
    }
    let color = type == 'product' ? '#29D0BC' : '#0035C5';
    let title = type == 'product' ? 'Διακύμανση προϊόντος' : 'Διακύμανση κατηγορίας';
    return {
      chart: {
        type: 'area'
      },
      title: {
        text: title,
        style: {
          fontSize: '10px'
        }
      },
      xAxis: {
        visible: false,
        categories: categories
      },
      yAxis: {
        visible: false
      },
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        valueSuffix: '€/τεμ',
        outside: true
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, color], [1, Highcharts.color(color).setOpacity(0).get('rgba')]]
          }
        },
        series: {
          color: color
        }
      }
    };
  });
});