define("katanalotis-microsite/services/route-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    router: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.router.on('routeDidChange', () => {
        console.count('routeDidChange');
        this.trigger('routeChanged', this.router.currentURL);
      });
    }
  });
});