define("katanalotis-microsite/initializers/cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'cart', 'service:cart');
    application.inject('controller', 'cart', 'service:cart');
    application.inject('component', 'cart', 'service:cart');
    application.inject('router:main', 'cart', 'service:cart');
  }
  var _default = _exports.default = {
    name: 'cart',
    initialize
  };
});