define("katanalotis-microsite/routes/product-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    resultsService: Ember.inject.service("results-preview"),
    actions: {
      didTransition() {
        console.count('didTransition');
        let controller = this.controllerFor('product-preview');
        controller.send('handleTransition', this.get("resultsService").categoryId, this.get("resultsService").subcategoryId);
        if (!this.get("resultsService").categoryId) {
          this.transitionTo("");
        } else {
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
        }
      }
    }
  });
});