define("katanalotis-microsite/components/sparkline-chart", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didReceiveAttrs() {
      Ember.run.scheduleOnce("afterRender", () => {
        /* Highcharts.setOptions({
            colors: ['#00DCC6', '#4C5D70', '#0099FF', '#8864FB', '#FFEB1F']
        }); */

        var h = Highcharts.chart(this.get('id'), {
          chart: {
            type: 'area',
            height: 50,
            width: 200,
            skipClone: true
          },
          title: {
            style: {
              display: 'none'
            }
          },
          exporting: {
            enabled: false
          },
          xAxis: {
            labels: {
              enabled: false
            },
            title: {
              text: null
            },
            startOnTick: false,
            endOnTick: false,
            tickPositions: []
          },
          yAxis: {
            endOnTick: false,
            startOnTick: false,
            labels: {
              enabled: false
            },
            title: {
              text: null
            },
            tickPositions: [0]
          },
          legend: {
            enabled: false
          },
          tooltip: {
            hideDelay: 0,
            outside: true,
            shared: true
          },
          series: [{
            data: this.get('data')
          }],
          credits: {
            enabled: false
          }
        });
      });
    }
  });
});