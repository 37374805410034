define("katanalotis-microsite/controllers/brochure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    actions: {
      selectCatalog(catalog) {
        Ember.set(this, 'selectedCatalog', catalog);
        $('#brochureModal').modal('show');
      }
    }
  });
});