define("katanalotis-microsite/components/product-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    router: Ember.inject.service('router'),
    products: Ember.computed('store', function () {
      const prods = this.get("store").peekAll("product");
      return prods;
    }),
    didRender() {
      this._super(...arguments);
      $('.search-input-container')[0].addEventListener('keyup', function () {
        $('.ember-power-select-options').css('display', 'block');
      });
    },
    routeState: Ember.inject.service(),
    didInsertElement() {
      this._super(...arguments);
      this.get('routeState').on('routeChanged', this, 'routeChanged');
    },
    willDestroyElement() {
      this._super(...arguments);
      this.get('routeState').off('routeChanged', this, 'routeChanged');
    },
    routeChanged(newURL) {
      // React to the route change here
      if (!newURL.includes('/product/')) {
        this.set('selectedProduct', null);
      }
    },
    actions: {
      goToProduct(product) {
        $('.ember-power-select-options').css('display', 'none');
        this.set('selectedProduct', product);
        this.get('router').transitionTo('product', product.id);
      }
    }
  });
});