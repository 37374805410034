define("katanalotis-microsite/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cbmDTw6n",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"navbar\"],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[1,[22,\"footer\"],false],[0,\"\\n\"],[1,[22,\"cookies\"],false],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"source\"]],\"discount_badge\"],null]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"badge-backdrop\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"badge-wrapper\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"class\",\"badge-icon\"],[10,\"src\",\"/assets/discount_badge.png\"],[8],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"badge-title\"],[8],[0,\"Πρωτοβουλία \"],[7,\"br\",true],[8],[9],[0,\"«Μόνιμη Μείωση Τιμής»\"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"badge-text\"],[8],[0,\"σε καταναλωτικά πρϊόντα κατά τουλάχιστον 5% και για τουλάχιστον 6 μήνες με ειδική σήμανση\\n            στο ράφι του\\n            supermarket.\"],[9],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"badge-btn\"],[3,\"action\",[[23,0,[]],\"closeBadge\"]],[8],[0,\"Ενημερώθηκα\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "katanalotis-microsite/templates/application.hbs"
    }
  });
});