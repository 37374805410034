define("katanalotis-microsite/templates/components/category-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vhJEI427",
    "block": "{\"symbols\":[\"subcat\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"category-item\"],[8],[0,\"\\n    \"],[5,\"image-with-fallback\",[[3,\"action\",[[23,0,[]],\"handleCategoryClick\"]]],[[\"@src\",\"@alt\",\"@fallbackSrc\"],[[29,[\"/assets/categories/\",[24,[\"category\",\"preview_img\"]]]],[29,[[24,[\"category\",\"name\"]]]],\"/assets/default_kalathi.png\"]]],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"category-name\"],[8],[1,[24,[\"category\",\"name\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"showPopNav\"]],true],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"subcategory-popanv\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"subcategories\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",false],[12,\"class\",\"subcat-item\"],[3,\"action\",[[23,0,[]],\"handleSubcategoryClick\",[23,1,[]]]],[8],[0,\"\\n                    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "katanalotis-microsite/templates/components/category-card.hbs"
    }
  });
});