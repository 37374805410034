define("katanalotis-microsite/controllers/product-preview", ["exports", "katanalotis-microsite/utils/filtering"], function (_exports, _filtering) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    showAllRetailers: false,
    showMoreRetailers: false,
    selectedSubSubCategoryId: null,
    isMobile: Ember.computed(function () {
      if (/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    }),
    filterExpanded: false,
    selectedPage: 1,
    itemsPerPage: 20,
    filerOptions: [{
      value: null,
      label: "Όλα"
    }, {
      value: 'monimi_meiosi',
      label: "Προϊόντα μόνιμης μείωσης τιμών"
    }],
    // Sort the brands and add them to brandOptions
    brandsSorted: Ember.computed('selectedCategory.brands.@each.name', function () {
      const selectedCategory = this.get('selectedCategory');
      if (selectedCategory && selectedCategory.brands) {
        return selectedCategory.brands.sortBy('name');
      } else {
        return [];
      }
    }),
    brandOptions: Ember.computed('brandsSorted', 'categoryProducts', function () {
      const sortedBrands = this.get('brandsSorted');
      let categoryProducts = this.get('categoryProducts');
      let allOption = [];
      if (categoryProducts) {
        // collect supplier names from the products
        let supplier_ids = categoryProducts.map(item => {
          try {
            // Check if supplier is not null and not undefined before calling toString()
            if (item.supplier != null) {
              return item.supplier.toString();
            } else {
              // Handle the case where supplier is null or undefined
              return 'default_supplier_id'; // You can return whatever makes sense in your context
            }
          } catch (error) {
            console.error('Error converting supplier to string:', error);
            return 'error_supplier_id'; // Fallback supplier id in case of an error
          }
        });
        allOption = this.get('store').peekAll("supplier").filter(function (item) {
          return supplier_ids.includes(item.id.toString());
        }).map(function (item) {
          return {
            "value": item.id,
            "label": item.name
          };
        });
      } else {
        allOption = this.get('store').peekAll("supplier").map(function (item) {
          return {
            "value": item.id,
            "label": item.name
          };
        });
      }
      return [[{
        "value": null,
        "label": "Όλες"
      }], ...allOption];
    }),
    selectedFilterOption: [{
      value: null,
      label: "Όλες"
    }],
    sortingOptions: [{
      value: 'ascending',
      label: 'Αύξουσα'
    }, {
      value: 'descending',
      label: 'Φθίνουσα'
    }],
    sorting: {
      value: 'ascending',
      label: 'Αύξουσα'
    },
    results: Ember.inject.service("results-preview"),
    observeResultsPreview: Ember.observer('results.categoryId', 'results.subcategoryId', function () {
      if (this.get('results').categoryId != this.get('selectedCategory').uuid) {
        console.count('notify category');
        this.notifyPropertyChange('selectedCategory');
      }
    }),
    product: Ember.computed("model", function () {
      return this.get("model");
    }),
    market: Ember.computed(function () {
      return this.get("store").peekAll("shop");
    }),
    checkedMarkets: Ember.computed("markets.@each.checked", function () {
      if (this.get("markets")) {
        return this.get("markets").filter(function (item) {
          if (item.checked) {
            return true;
          }
          return false;
        }).map(x => x.name);
      }
    }),
    // allSubSubCategories:  [{uuid:1,name:'Ψωμί για τοστ σίτου'},{uuid:2,name:"Ψωμί για τοστ σίκαλης & ολικής"}, {uuid:3, name: "Ψωμί Ζέας, Chia & άλλες γεύσεις για τοστ"}],
    allSubSubCategories: Ember.computed('selectedSubCategory', 'selectedSubCategory.sub_sub_categories', function () {
      const selectedSubCategory = this.get('selectedSubCategory');
      if (!selectedSubCategory) {
        return [];
      }
      return selectedSubCategory.sub_sub_categories || [];
    }),
    productsPaginated: Ember.computed("products", "products.@each", "selectedPage", function () {
      var indexStart = (this.get("selectedPage") - 1) * this.get("itemsPerPage");
      return this.get("products").slice(indexStart, this.get("itemsPerPage") + indexStart);
    }),
    lastPage: Ember.computed("products", "products.@each", "selectedPage", function () {
      return this.totalPages() == 1 ? null : this.totalPages();
    }),
    midPages: Ember.computed("totalPages", "selectedPage", function () {
      var selectedPage = this.get("selectedPage");
      var totalPages = this.totalPages();
      var lastPage = this.get("lastPage");
      if (totalPages > 2) {
        var midPages = [];
        if (selectedPage - 1 > 1) {
          midPages = [selectedPage];
          midPages.unshift(selectedPage - 1);
        } else if (selectedPage - 1 > 0) {
          midPages = [selectedPage];
        }
        if (lastPage - selectedPage > 1) {
          midPages.push(selectedPage + 1);
        } else if (lastPage - selectedPage > 0) {
          midPages = [selectedPage];
        }
        if (selectedPage - 1 > 1) {
          Ember.set(this, "showPrevDots", true);
        } else {
          Ember.set(this, "showPrevDots", false);
        }
        if (lastPage - selectedPage > 1) {
          Ember.set(this, "showAfterDots", true);
        } else {
          Ember.set(this, "showAfterDots", false);
        }
        // Remove the last page number if it's present
        const lastIndex = midPages.indexOf(lastPage);
        if (lastIndex !== -1) {
          midPages.splice(lastIndex, 1);
        }
        return midPages;
      }
      return null;
    }),
    totalPages() {
      return Math.ceil(this.get("products").length / this.get("itemsPerPage"));
    },
    productsCount: Ember.computed("products", function () {
      return this.get("products") ? this.get("products").length : 0;
    }),
    products: Ember.computed("checkedMarket", "sorting.value", "filteredProducts", "selectedFilterOption", function () {
      var self = this;
      var prods = this.get("filteredProducts");
      var market = this.get("checkedMarket");
      var selectedFilterOption = this.get("selectedFilterOption");
      var marketImg;
      var flag;
      if (market) {
        var prods = prods.filter(function (product) {
          for (var z = 0; z < product.prices.length; z++) {
            let market_uuid = product.prices[z].merchant_uuid;
            let price_market = self.get('store').peekRecord("merchant", market_uuid);
            if (product.prices[z].price && market == price_market.display_name) {
              flag = true;
              marketImg = market.image;
              Ember.set(product, "singlePrice", [product.prices[z]]);
              break;
            } else {
              flag = false;
            }
          }
          return flag;
        });
        Ember.set(this, "marketImg", marketImg);
      } else {
        Ember.set(this, "marketImg", null);
      }
      if (!prods) return [];
      Ember.set(this, "categoryProducts", prods); //for suppliers dropdown
      if (selectedFilterOption && selectedFilterOption.length > 0 && selectedFilterOption[0].value) {
        let ids = selectedFilterOption.map(function (item) {
          return item.value;
        });
        prods = prods.filter(p => ids.includes(p.supplier.toString()));
      }
      if (this.get("sorting").value == "ascending") {
        return prods.sortBy("minPrice");
      } else {
        return prods.sortBy("minPrice").reverse();
      }
      return prods;
    }),
    firstProductDate: Ember.computed('filteredProducts.[]', function () {
      const products = this.get('filteredProducts');

      // Check if there are any products available
      if (products && products.length > 0) {
        const firstProduct = products[0];
        try {
          // Parse the `extra_fields` JSON string to get the date
          const extraFields = JSON.parse(firstProduct.extra_fields);
          return extraFields.date; // Return the date if available
        } catch (e) {
          console.error('Error parsing extra_fields:', e);
          return null; // Handle parsing errors
        }
      }
      return null; // If no products are available
    }),
    changeOutline: Ember.observer("selectedSubCategory.name", function () {
      if (this.get("selectedSubCategory")) {
        $("#enabled").addClass("noOutline");
      }
    }),
    date: Ember.computed("productsCount", function () {
      var date = parseInt(this.get("productsCount")) > 0 ? JSON.parse(this.get("filteredProducts")[0].prices[this.get("filteredProducts")[0].prices.length - 1].extra_fields).date : null;
      Ember.set(this.get("cart"), "date", date);
      return date;
    }),
    categories: Ember.computed(function () {
      const cats = this.get("store").peekAll("category").sortBy("name");
      return cats;
    }),
    actions: {
      filterProductsByBrand(selectedBrand) {
        if (selectedBrand.length == 0) {
          selectedBrand = [{
            value: null,
            label: "Όλες"
          }];
        }
        if (selectedBrand.length > 1) {
          selectedBrand = selectedBrand.filter(function (item) {
            return item.value;
          });
        }
        Ember.set(this, "selectedFilterOption", selectedBrand);
      },
      filterProductsBySubSubCategory(subSubCategoryId) {
        this.set('selectedSubSubCategoryId', subSubCategoryId);
        if (!subSubCategoryId) {
          // If "Όλα" button is clicked, show all products of the selected subcategory
          const selectedSubcategoryId = this.get('selectedSubCategory.uuid');
          const products = this.get("store").peekAll("product").filter(product => {
            return product.sub_category === selectedSubcategoryId;
          });
          // Set the filtered products
          this.set('filteredProducts', products);
        } else {
          // Filter products by selected sub-sub-category
          const products = this.get("store").peekAll("product").filter(product => {
            return product.sub_sub_category === subSubCategoryId;
          });
          // Set the filtered products
          this.set('filteredProducts', products);
        }
      },
      handleTransition(categoryId, subcategoryId) {
        var self = this;
        if (!categoryId || !subcategoryId) return this.transitionToRoute('');
        this.set('selectedPage', 1);
        const categories = this.get('categories');
        const selectedCategory = categories.find(cat => {
          return cat.uuid == categoryId;
        });
        this.set('selectedCategory', selectedCategory);
        const subCategories = selectedCategory.sub_categories.sortBy("name");
        this.set('subCategories', subCategories);
        const selectedSubCategory = subCategories.find(cat => {
          return cat.uuid == subcategoryId;
        });
        this.set('selectedSubCategory', selectedSubCategory);
        const products = this.get("store").peekAll("product").filter(product => product.sub_category == subcategoryId);
        this.set('filteredProducts', products);
        var markets = [];
        for (var i = 0; i < products.length; i++) {
          var product = products[i];
          for (var z = 0; z < product.prices.length; z++) {
            let market_uuid = product.prices[z].merchant_uuid;
            let market = self.get('store').peekRecord("merchant", market_uuid);
            if (market && !markets.some(m => m.display_name === market.display_name)) {
              markets.push({
                display_name: market.display_name,
                name: market.name
              });
            }
          }
        }
        markets.sort((a, b) => a.name > b.name ? 1 : -1);
        var displayNames = markets.map(market => market.display_name);
        this.set('markets', displayNames.map(x => ({
          name: x,
          checked: false
        })));
      },
      handleCatSelect(e) {
        var self = this;
        const categoryId = e.uuid;
        const categories = this.get('categories');
        const selectedCategory = categories.find(cat => cat.uuid === categoryId);
        this.set('selectedCategory', selectedCategory);
        const subCategories = selectedCategory.sub_categories.sortBy("name");
        this.set('subCategories', subCategories);
        const products = this.get("store").peekAll("product").filter(product => product.category === categoryId);
        this.set('filteredProducts', products);
        let markets = [];
        for (let i = 0; i < products.length; i++) {
          let product = products[i];
          for (var z = 0; z < product.prices.length; z++) {
            let market_uuid = product.prices[z].merchant_uuid;
            let market = self.get('store').peekRecord("merchant", market_uuid);
            if (market && !markets.includes(market.display_name)) {
              markets.push(market.display_name);
            }
          }
        }
        this.set('markets', markets.map(x => ({
          name: x,
          checked: false
        })));

        // Defer setting selectedSubCategory to null to ensure it happens after other property changes
        Ember.run.next(this, function () {
          this.set('selectedSubCategory', null);
          this.set('selectedFilterOption', [{
            value: null,
            label: "Όλες"
          }]);
        });
      },
      handleSubcatSelect(e) {
        var self = this;
        console.count('handleSubcatSelect');
        const subcategoryId = e.uuid;
        console.log(subcategoryId);
        const subCategories = this.get('subCategories');
        const selectedSubCategory = subCategories.find(cat => {
          return cat.uuid == subcategoryId;
        });
        console.log(subCategories);
        // TODO 3rd level cateegory
        this.set('selectedSubCategory', selectedSubCategory);
        console.log(selectedSubCategory);
        const products = this.get("store").peekAll("product").filter(product => product.sub_category == subcategoryId);
        console.log(products);
        this.set('filteredProducts', products);
        var markets = [];
        this.set("selectedPage", 1);
        for (var i = 0; i < products.length; i++) {
          var product = products[i];
          for (var z = 0; z < product.prices.length; z++) {
            let market_uuid = product.prices[z].merchant_uuid;
            let market = self.get('store').peekRecord("merchant", market_uuid);
            if (market && !markets.includes(market.display_name)) {
              markets.push(market.display_name);
            }
          }
        }
        this.set('markets', markets.map(x => ({
          name: x,
          checked: false
        })));
        this.set('selectedFilterOption', [{
          value: null,
          label: "Όλες"
        }]);
      },
      clearFilter() {
        this.set('selectedPage', 1);
        Ember.set(this, 'checkedMarket', null);
      },
      toggleRetailers() {
        this.toggleProperty("showAllRetailers");
      },
      toggleMoreRetailers() {
        this.toggleProperty("showMoreRetailers");
      },
      addToCart(product) {
        this.cart.add(product);
        Ember.set(product, "isInBasket", true);
      },
      removePiece(product) {
        this.cart.removePiece(product);
      },
      click() {
        let clickable = true;
      },
      decrementPage() {
        if (this.get("selectedPage") != 1) {
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
          Ember.set(this, "selectedPage", this.get("selectedPage") - 1);
        }
      },
      incrementPage() {
        if (this.get("selectedPage") != this.totalPages()) {
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
          Ember.set(this, "selectedPage", this.get("selectedPage") + 1);
        }
      },
      selectPage(page) {
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
        Ember.set(this, "selectedPage", page);
      }
    }
  });
});