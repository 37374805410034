define("katanalotis-microsite/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    actions: {
      closeBadge() {
        Ember.set(this, 'source', '');
      }
    }
  });
});