define("katanalotis-microsite/models/toy-retailer", ["exports", "ember-data", "highcharts"], function (_exports, _emberData, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    basket: _emberData.default.attr(),
    active: _emberData.default.attr('boolean')
  });
});