define("katanalotis-microsite/controllers/product", ["exports", "ember-resolver"], function (_exports, _emberResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    selectedSorting: 'Φθίνουσα Σειρά',
    sortingOptions: ['Φθίνουσα Σειρά', 'Αύξουσα Σειρά'],
    product: Ember.computed('model', function () {
      return this.get('model');
    }),
    productDate: Ember.computed('product.extra_fields', function () {
      const product = this.get('product');
      if (!product || !product.extra_fields) {
        return null; // Return null if product or extra_fields is not available
      }
      try {
        const extraFields = JSON.parse(product.extra_fields); // Parse extra_fields JSON
        const date = extraFields.date; // Extract the date
        console.log("Parsed date from extra_fields:", date); // Log the parsed date for debugging
        return date; // Return the extracted date
      } catch (e) {
        console.log('Error parsing extra_fields:', e);
        return null; // Return null if parsing fails
      }
    }),
    marketPrices: Ember.computed('product', 'selectedSorting', function () {
      var product = this.get('product');
      var prices = this.get('selectedSorting') == 'Φθίνουσα Σειρά' ? product.prices.sortBy('price') : product.prices.sortBy('price').reverse();
      var self = this;
      return prices.map(function (item) {
        var m = self.get('store').peekRecord('merchant', item.merchant_uuid);
        if (m) {
          item.merchant = {
            "image": m.image,
            "name": m.name,
            "display_name": m.display_name
          };
        }
        return item;
      });
    }),
    firstProductDate: Ember.computed('filteredProducts.@each.extra_fields', function () {
      const filteredProducts = this.get('filteredProducts');
      console.log('Filtered Products:', filteredProducts);
      if (filteredProducts && filteredProducts.length > 0) {
        const firstProduct = filteredProducts[0];
        console.log('First Product:', firstProduct);
        if (firstProduct.prices && firstProduct.prices.length > 0) {
          const lastPriceIndex = firstProduct.prices.length - 1;
          console.log('Last Price Entry:', firstProduct.prices[lastPriceIndex]);
          if (firstProduct.prices[lastPriceIndex].extra_fields) {
            try {
              const extraFields = JSON.parse(firstProduct.prices[lastPriceIndex].extra_fields);
              const date = extraFields.date;
              console.log("Parsed date from extra_fields:", date);
              return date;
            } catch (e) {
              console.error('Error parsing extra_fields:', e);
              return null;
            }
          } else {
            console.warn('No extra_fields found for the last price entry.');
          }
        } else {
          console.warn('No prices found for the first product.');
        }
      } else {
        console.warn('No filtered products found.');
      }
      return null;
    }),
    subCategory: Ember.computed('product', function () {
      const category = this.get('store').peekRecord('category', this.get('product').category);
      return category.sub_categories.find(subcategory => subcategory.uuid == this.get('product').sub_category).name;
    }),
    category: Ember.computed('product', function () {
      return this.get('store').peekRecord('category', this.get('product').category);
    }),
    pricePerDay: Ember.computed('product', function () {
      return this.get('store').peekAll('price-per-day').slice(-6);
    }),
    pricePerDayDates: Ember.computed('pricePerDay', function () {
      return 0; //FIXME this.get('pricePerDay').map(item => item.Day_Month)
    }),
    pricePerDayAvg: Ember.computed('pricePerDay', function () {
      return 0; //FIXME  this.get('pricePerDay').map(item => item.Avg_Price)
    }),
    avgMax: Ember.computed('pricePerDayAvg', function () {
      return 0; //FIXME Math.max(...this.get('pricePerDayAvg'))
    }),
    avgMin: Ember.computed('pricePerDayAvg', function () {
      return 0; //FIXME Math.min(...this.get('pricePerDayAvg'))
    }),
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    formatDate(date) {
      return [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
    },
    date: Ember.computed(function () {
      var dates = this.get('product').prices.map(x => {
        var date = JSON.parse(x.extra_fields).date;
        date = date.split('/').reverse().join('/');
        return new Date(date).getTime();
      });
      var maxDate = Math.max(...dates);
      maxDate = this.formatDate(new Date(maxDate));
      return maxDate;
    }),
    chartOptions: Ember.computed('pricePerDayDates', function () {
      const self = this;
      return {
        chart: {
          type: 'area'
        },
        title: {
          text: 'Εξέλιξη Τιμής προηγούμενης εβδομάδας'
        },
        xAxis: {
          categories: this.get('pricePerDayDates')
        },
        yAxis: {
          labels: {
            formatter: function () {
              return parseFloat(this.axis.defaultLabelFormatter.call(this)).toFixed(2) + '€';
            }
          },
          title: {
            text: ''
          },
          max: this.get('avgMax') + 0.02,
          min: this.get('avgMin') - 0.02
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          valueSuffix: '€'
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [[0, '#29D0BC'], [1, Highcharts.color('#29D0BC').setOpacity(0).get('rgba')]]
            }
          },
          series: {
            color: '#29D0BC'
          }
        }
      };
    }),
    chartData: Ember.computed('pricePerDayDates', function () {
      const self = this;
      return [{
        name: '',
        data: this.get('pricePerDayAvg')
      }];
    }),
    actions: {
      addToCart(product) {
        this.cart.add(product);
        Ember.set(product, 'isInBasket', true);
      },
      goBack() {
        window.history.back();
      },
      removePiece(product) {
        this.cart.removePiece(product);
      },
      toggleSorting() {
        if (this.get('selectedSorting') == 'Φθίνουσα Σειρά') {
          Ember.set(this, 'selectedSorting', 'Αύξουσα Σειρά');
        } else {
          Ember.set(this, 'selectedSorting', 'Φθίνουσα Σειρά');
        }
      }
    }
  });
});