define("katanalotis-microsite/controllers/students-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    selectedPage: 1,
    itemsPerPage: 51,
    displayOptions: {
      diabetes: "ΧΑΜΗΛΟΣ ΓΛΥΚΑΙΜΙΚΟΣ ΔΕΙΚΤΗΣ"
    },
    products: Ember.computed("model", function () {
      try {
        console.log(this.get("model"));
        return this.get("model").get("firstObject").basket;
      } catch (e) {
        console.log(e);
      }
    }),
    productsPaginated: Ember.computed("products", "products.@each", "selectedPage", function () {
      if (this.get("products")) {
        var indexStart = (this.get("selectedPage") - 1) * this.get("itemsPerPage");
        return this.get("products").slice(indexStart, this.get("itemsPerPage") + indexStart);
      }
    }),
    lastPage: Ember.computed("products", "products.@each", "selectedPage", function () {
      if (this.get("products")) {
        return this.totalPages() == 1 ? null : this.totalPages();
      }
    }),
    midPages: Ember.computed("products", "products.@each", "selectedPage", function () {
      if (this.get("products")) {
        var selectedPage = this.get("selectedPage");
        var totalPages = this.totalPages();
        var lastPage = this.get("lastPage");
        if (totalPages > 3) {
          var midPages = [];
          if (selectedPage - 1 > 1) {
            midPages = [selectedPage];
            midPages.unshift(selectedPage - 1);
          } else if (selectedPage - 1 > 0) {
            midPages = [selectedPage];
          }
          if (lastPage - selectedPage > 1) {
            midPages.push(selectedPage + 1);
          } else if (lastPage - selectedPage > 0) {
            midPages = [selectedPage];
          }
          if (selectedPage - 1 > 1) {
            Ember.set(this, "showPrevDots", true);
          } else {
            Ember.set(this, "showPrevDots", false);
          }
          if (lastPage - selectedPage > 1) {
            Ember.set(this, "showAfterDots", true);
          } else {
            Ember.set(this, "showAfterDots", false);
          }
          return midPages;
        } else if (totalPages == 3) {
          return [2];
        }
        return null;
      }
    }),
    totalPages() {
      if (this.get("products")) {
        return Math.ceil(this.get("products").length / this.get("itemsPerPage"));
      } else {
        return 0;
      }
    },
    pricePerDayAvg: Ember.computed(function () {
      return this.get("variance").map(item => item.Avg_Price);
    }),
    pricePerDayDates: Ember.computed(function () {
      return this.get("variance")[0].map(item => item.Day_Month);
    }),
    chartOptionsProduct: Ember.computed(function () {
      const self = this;
      return {
        chart: {
          type: "area"
        },
        title: {
          text: "Τιμή προϊόντος",
          style: {
            fontSize: "10px"
          }
        },
        xAxis: {
          visible: false,
          categories: self.get("pricePerDayDates")
        },
        yAxis: {
          visible: false
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          valueSuffix: "€/τεμ"
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [[0, "#29D0BC"], [1, Highcharts.color("#29D0BC").setOpacity(0).get("rgba")]]
            }
          },
          series: {
            color: "#29D0BC"
          }
        }
      };
    }),
    chartOptionsCategory: Ember.computed(function () {
      const self = this;
      return {
        chart: {
          type: "area"
        },
        title: {
          text: "Διακύμανση κατηγορίας",
          style: {
            fontSize: "10px"
          }
        },
        xAxis: {
          visible: false,
          categories: self.get("pricePerDayDates")
        },
        yAxis: {
          visible: false
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          valueSuffix: "€/τεμ"
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [[0, "#0035C5"], [1, Highcharts.color("#0035C5").setOpacity(0).get("rgba")]]
            }
          },
          series: {
            color: "#0035C5"
          }
        }
      };
    }),
    actions: {
      selectRetailer(retailer) {
        this.get("model").forEach(x => x.active = false);
        retailer.active = true;
        Ember.set(this, "products", retailer.basket);
        Ember.set(this, "selectedPage", 1);
      },
      decrementPage() {
        if (this.get("selectedPage") != 1) {
          Ember.set(this, "selectedPage", this.get("selectedPage") - 1);
        }
      },
      incrementPage() {
        if (this.get("selectedPage") != this.totalPages()) {
          Ember.set(this, "selectedPage", this.get("selectedPage") + 1);
        }
      },
      selectPage(page) {
        Ember.set(this, "selectedPage", page);
      }
    }
  });
});