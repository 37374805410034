define("katanalotis-microsite/templates/index-news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5VsZlGD7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"index-news-article\"],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[24,[\"model\",\"img\"]]],[10,\"class\",\"article-news-img\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"index-article-right\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"article-news-title\"],[8],[1,[24,[\"model\",\"name\"]],false],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"article-news-desc\"],[8],[1,[24,[\"model\",\"description\"]],false],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "katanalotis-microsite/templates/index-news.hbs"
    }
  });
});