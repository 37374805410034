define("katanalotis-microsite/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    successGetProducts(data) {
      let self = this;
      var categories = data.context.MAPP_PRODUCTS.result.categories;
      var products = data.context.MAPP_PRODUCTS.result.products;
      var suppliers = data.context.MAPP_PRODUCTS.result.suppliers;
      var merchants = data.context.MAPP_PRODUCTS.result.merchants || {};
      var img_base_url = data.context.MAPP_PRODUCTS.result.img_base_url;
      const categoryImageDict = {
        "Kρέας & Ψάρια": "kreas.png",
        "Άρτος & Είδη φούρνου": "artos.png",
        "Βρεφικά Είδη": "vrefika.png",
        "Γλυκά & Σοκολάτες": "glika.png",
        "Είδη Προσωπικής φροντίδας": "prosopika.png",
        "Είδη Ψυγείου & Γαλακτοκομικά": "psigiou.png",
        "Καθαριστικά": "katharistika.png",
        "Κατεψυγμένα": "katepsigmena.png",
        "Κατοικίδια": "katikidia.png",
        "Οπωροκηπευτικά": "oporokipeftika.png",
        "Ποτά & Αναψυκτικά": "pota.png",
        "Τυποποιημένα": "tipopiimena.png",
        "Τυριά & Αλλαντικά": "tiria.png",
        // νεες
        "Είδη Αρτοζαχαροπλαστείου": "artos.png",
        "Γάλατα, Ροφήματα & Χυμοί ψυγείου": "galata-rofhmata.png",
        "Γιαούρτια, Κρέμες γάλακτος & Επιδόρπια ψυγείου": "giaourtia-kremes.png",
        "Απορρυπαντικά & Είδη Καθαρισμού": "aporypantika.png",
        "Καλλυντικά & Είδη Προσωπικής υγιεινής": "kallyntika.png",
        "Χαρτικά, Πάνες & Σερβιέτες": "xartika-panes.png",
        "Είδη μιας χρήσης & Είδη Πάρτι": "eidh-party.png",
        "Αλλαντικά": "allantika.png",
        "Τρόφιμα Παντοπωλείου": "trofhma-pantopwleiou.png",
        "Βρεφικές & Παιδικές τροφές": "vrefikes-kremes.png",
        "Είδη πρωινού & Ροφήματα": "eidh-prwinou.png",
        "Μπισκότα, Σοκολάτες & Ζαχαρώδη": 'mpiskota-sokolates.png',
        "Ξηροί Καρποί & Σνακ": "kshroi karpoi-snack.png",
        "Αναψυκτικά, Νερά & Χυμοί": "anapsyktika-nera.png",
        "Είδη Οικιακής χρήσης": "eidh-oikiakhsxrhshs.png",
        "Κατεψυγμένα": "katepsygmena.png",
        "Αυγά, Βούτυρο, Νωπές Ζύμες & Ζωμοί": "ayga-voutyro.png",
        "Τυροκομικά & Φυτικά Αναπληρώματα": "tyrokomika.png",
        "Τροφές & Είδη για Κατοικίδια": "katoikidia.png",
        "Ορεκτικά & Delicatessen": "orektika-delicatessen.png",
        "Φρέσκο Κρέας": "fresco-kreas.png",
        "Φρέσκο Ψάρι & Θαλασσινά": "fresko-psari.png",
        "Φρέσκα Φρούτα & Λαχανικά": "frouta-laxanika.png",
        "Έτοιμα Γεύματα": "etoima-geumata.png",
        "Κάβα": "kava.png"
      };
      $.each(categories, function (i, item) {
        try {
          self.store.createRecord("category", {
            name: item.name,
            uuid: item.uuid,
            id: item.uuid,
            sub_categories: item.sub_categories,
            preview_img: categoryImageDict[item.name] || 'default.png'
          });
        } catch (e) {
          console.log(e);
          console.log(item);
        }
      });
      $.each(products, function (i, item) {
        if (item.prices.find(x => x.price != 0)) {
          self.store.createRecord("product", {
            name: item.name,
            barcode: item.barcode,
            image: img_base_url + item.image,
            category: item.category[0],
            sub_category: item.category[1],
            sub_sub_category: item.category[2],
            supplier: item.supplier,
            prices: item.prices,
            id: i,
            minPrice: item.prices.sortBy("price").find(x => x.price != 0).price,
            monimi_meiosi: item.monimi_meiosi,
            promo: item.promo,
            extra_fields: item.extra_fields
          });
        }
      });
      $.each(suppliers, function (i, item) {
        try {
          self.store.createRecord("supplier", {
            name: item.name,
            id: item.id
          });
        } catch (e) {
          console.log(e);
          console.log(item);
        }
      });
      $.each(Object.values(merchants), function (i, item) {
        try {
          self.store.createRecord("merchant", {
            name: item.name,
            display_name: item.display_name || item.name,
            id: item.merchant_uuid,
            image: 'https://warply.s3.eu-west-1.amazonaws.com/applications/ed840ad545884deeb6c6b699176797ed/basket-retailers/' + item.image
          });
        } catch (e) {
          console.log(e);
          console.log(item);
        }
      });
    },
    errorGetProducts(data) {
      console.log(data);
    },
    successGetContent() {
      let self = this;

      // self.store.createRecord('article', {
      //     img: '/assets/easterBasket.png',
      //     name: 'Το καλάθι των Νονών',
      //     custom_description: 'Βρείτε τις τιμές σε επιλεγμένα προϊόντα για τις πασχαλινές σας αγορες!',
      //     category_name: 'easterBasket',
      //     custom_btn: 'Δες το καλάθι'
      // });
      self.store.createRecord("article", {
        img: "/assets/banners/vegetableBasket.png",
        name: "Καλάθι οπωροκηπευτικών",
        custom_description: "Βρείτε τις τιμές σε επιλεγμένα προϊόντα για το καλαθι των οπωροκηπευτικων!",
        category_name: "freshBasket",
        custom_btn: "Μάθε περισσότερα"
      });
      self.store.createRecord('article', {
        img: '/assets/studentsBasket.jpg',
        name: 'Το καλάθι των Μαθητών',
        custom_description: 'Βρείτε τις τιμές σε επιλεγμένα προϊόντα για τις σχολικές σας αγορές!',
        category_name: 'studentsBasket',
        custom_btn: 'Μάθε περισσότερα'
      });
      // self.store.createRecord("article", {
      //   img: "/assets/banners/energyBasket.png",
      //   name: "Οικιακά Τιμολόγια Ρεύματος",
      //   custom_description: "Βρείτε τις τιμές σε προγράμματα παρόχων ρεύματος!",
      //   category_name: "energyBasket",
      //   custom_btn: "Μάθε περισσότερα",
      // });
      self.store.createRecord("article", {
        img: "/assets/banners/householdBasket.png",
        name: "Το καλάθι του νοικοκυριού",
        custom_description: "Βρείτε τις τιμές σε επιλεγμένα προϊόντα για το καλάθι του νοικοκυριού!",
        category_name: "householdBasket",
        custom_btn: "Μάθε περισσότερα"
      });
      self.store.createRecord("article", {
        img: "/assets/banners/brochure.png",
        name: "Βρείτε φυλλάδια προσφορών",
        // custom_description:
        //   "Δείτε όλες τις προσφορές σε φυλλάδια αλυσίδων σουπερμάρκετ.",
        category_name: "brochure",
        custom_btn: "Ανακάλυψέ τα"
      });
      self.store.createRecord("article", {
        img: "/assets/banners/useful.png",
        name: "Χρήσιμες πληροφορίες για τον καταναλωτή",
        custom_description: "",
        category_name: "useful",
        custom_btn: "Μάθε περισσότερα"
      });
    },
    model(params) {
      const self = this;
      try {
        const source = this._router.currentState.routerJsState.fullQueryParams.source;
        this.controllerFor("application").set("source", source);
      } catch (e) {
        console.log(e);
      }
      this.successGetContent();
      const cacheDuration = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
      const now = new Date().getTime();
      const nearest12HourInterval = Math.floor(now / cacheDuration) * cacheDuration;
      const promise1 = new Ember.RSVP.Promise((resolve, reject) => {
        Ember.$.getJSON(`https://warply.s3.amazonaws.com/applications/ed840ad545884deeb6c6b699176797ed/basket-retailers/prices.json?cid=${nearest12HourInterval}`).then(data => {
          resolve(data);
          self.successGetProducts(data);
        }).fail(error => {
          reject(error);
        });
      });
      return new Promise(function (resolve) {
        return Ember.RSVP.all([promise1]).then(() => {
          $(".lds-circle").hide();
          resolve(true);
        });
      });
    }
  });
});