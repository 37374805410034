define("katanalotis-microsite/models/energy-product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    operator: _emberData.default.attr(),
    productLogo: _emberData.default.attr(),
    productName: _emberData.default.attr(),
    base: _emberData.default.attr(),
    unit_value: _emberData.default.attr(),
    contract_value: _emberData.default.attr(),
    perc: _emberData.default.attr(),
    sparkline: _emberData.default.attr()
  });
});