define("katanalotis-microsite/helpers/product-price-sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function productPriceSum(params /*, hash*/) {
    var result = params[0].price * params[0].count;
    return result % 1 == 0 ? result : result.toFixed(2);
  });
});