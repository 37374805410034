define("katanalotis-microsite/helpers/set-chart-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function setChartData(params /*, hash*/) {
    if (params.length == 2) {
      let variance = params[0];
      let category = params[1] - 1;
      try {
        let data = variance[category].map(item => parseFloat(item.Price));
        return [{
          name: '',
          data: data
        }];
      } catch (e) {
        return [{
          name: '',
          data: []
        }];
      }
    } else {
      try {
        let data = params[0].map(item => parseFloat(item.Price));
        return [{
          name: '',
          data: data
        }];
      } catch (e) {
        return [{
          name: '',
          data: []
        }];
      }
    }
  });
});