define("katanalotis-microsite/models/fuelstation-pin", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    admin_name: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    region: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    merchant_uuid: _emberData.default.attr('string'),
    store_id: _emberData.default.attr('string'),
    postal_code: _emberData.default.attr('string'),
    vat: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    price: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    category_uuid: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('string'),
    longitude: _emberData.default.attr('string'),
    uuid: _emberData.default.attr('string')
  });
});