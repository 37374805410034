define("katanalotis-microsite/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ["stores"],
    stores: null,
    selectedCategory: null,
    selectedSubCategory: null,
    products: Ember.computed(function () {
      return this.get("store").peekAll("product");
    }),
    results: Ember.inject.service("index-news"),
    resultsService: Ember.inject.service("results-preview"),
    articles: Ember.computed(function () {
      return this.get("store").peekAll("article");
    }),
    offers: Ember.computed(function () {
      return this.get("store").peekAll("offer");
    }),
    displayContact: Ember.computed("articles", function () {
      return this.get("articles").find(article => article.category_name == "contact_form").active;
    }),
    displayAntiseptic: Ember.computed("articles", function () {
      return this.get("articles").find(article => article.category_name == "antiseptic").active;
    }),
    selectedCategory: Ember.computed("results.category", function () {
      return this.get("results").category;
    }),
    selectedSubCategory: Ember.computed("results.subCategory", function () {
      return this.get("results").subCategory;
    }),
    categories: Ember.computed(function () {
      const cats = this.get("store").peekAll("category").map(category => {
        return category;
      }).sortBy("name");
      return cats;
    }),
    subCategories: Ember.computed("selectedCategory", function () {
      return this.get("selectedCategory") ? this.get("store").peekRecord("category", this.get("selectedCategory").id).sub_categories.sortBy("name") : null;
    }),
    filteredProducts: Ember.computed("selectedSubCategory.name", function () {
      if (this.get("selectedSubCategory")) {
        var products = this.get("store").peekAll("product").filter(product => product.sub_category == this.get("selectedSubCategory").uuid);
      }
      return products;
    }),
    products: Ember.computed("checkedMarket", "sorting", "filteredProducts", function () {
      var prods = this.get("filteredProducts");
      var market = this.get("checkedMarket");
      var marketImg;
      var flag;
      if (market) {
        var prods = prods.filter(function (product) {
          for (var z = 0; z < product.prices.length; z++) {
            if (product.prices[z].price && market == product.prices[z].merchant.name) {
              flag = true;
              marketImg = product.prices[z].merchant.image;
              Ember.set(product, "singlePrice", [product.prices[z]]);
              break;
            } else {
              flag = false;
            }
          }
          return flag;
        });
        Ember.set(this, "marketImg", marketImg);
      } else {
        Ember.set(this, "marketImg", null);
      }
      if (this.get("sorting") == "ascending") {
        return prods.sortBy("minPrice");
      } else {
        return prods.sortBy("minPrice").reverse();
      }
    }),
    actions: {
      handleCategoryChange(category) {
        this.set("selectedCategory", category);
        // Reset selectedSubCategory to null when category changes
        this.set("selectedSubCategory", null);
      },
      getSubcategories(category) {
        console.log(category);
        return this.get("store").peekRecord("category", category.id).sub_categories.sortBy("name");
      },
      goToCategory(category, subcategoryId) {
        // Reset error flags
        this.set("subCategoryError", false);
        this.set("categoryError", false);

        // Check if category is missing
        if (!category) {
          this.set("categoryError", true);
          console.warn("Category is missing");
        }

        // Check if subcategory is missing
        if (!subcategoryId) {
          this.set("subCategoryError", true);
          console.warn("Subcategory is missing");
        }

        // Check if either category or subcategory is missing
        if (!category || !subcategoryId) {
          console.warn("Something is missing");
          return;
        }
        console.log("Selected category:", category.id);
        console.log("Selected subcategory:", subcategoryId);

        // Clear selectedCategory and selectedSubCategory
        this.set("selectedCategory", null);
        this.set("selectedSubCategory", null);

        // Set category and subcategory IDs in resultsService
        this.get("resultsService").set("categoryId", category.id);
        this.get("resultsService").set("subcategoryId", subcategoryId);

        // Transition to product-preview route
        this.transitionToRoute("product-preview");
      },
      goToProduct(product) {
        this.transitionToRoute("product", product.id);
      },
      goToNews() {
        this.transitionToRoute("index-news");
        Ember.set(this, "results.article", this.get("articles"));
      }
    }
  });
});