define("katanalotis-microsite/helpers/in-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function inRange(params /*, hash*/) {
    var lowerBound = true;
    var upperBound = true;
    if (params[1])
      //minimum
      if (params[1] >= params[0]) lowerBound = false;
    if (params[2])
      //max
      if (params[2] <= params[0]) upperBound = false;
    return upperBound && lowerBound;
  });
});