define("katanalotis-microsite/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    actions: {
      didTransition() {
        if (this.get('controller').stores) {
          var scrollBottom = $(window).scrollTop() + $(window).height();
          $("html, body").animate({
            scrollTop: scrollBottom
          }, "slow");
          Ember.set(this.get('controller'), 'stores', null);
        } else {
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
        }
      }
    }
  });
});