define("katanalotis-microsite/controllers/click-away", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    actions: {
      request() {
        this.get('warply_sdk').post_context(JSON.stringify({
          "products": {
            "action": "click_away",
            "subaction": "generate_coupon"
          }
        }), this.success.bind(this), this.failure);
      },
      rantevou() {
        let branchName = 4994;
        let afm = this.get('afm');
        let dieu8insi = this.get('dieu8insi');
        let date = this.get('date');
        let time = this.get('time');
        let code = this.get('customerCode');
        let message = this.get('messageText');
        if (this.get('model') === 'inside') {
          var click = true;
        } else {
          var click = false;
        }
        var inputError = false;
        if (!afm || afm && (afm.length != 9 || !parseInt(afm))) {
          $('#afm').addClass('error-input');
          inputError = true;
        } else {
          $('#afm').removeClass('error-input');
        }
        if (!dieu8insi) {
          $('#dieu8insi').addClass('error-input');
          inputError = true;
        } else {
          $('#dieu8insi').removeClass('error-input');
        }
        if (!date) {
          $('#date').addClass('error-input');
          inputError = true;
        } else {
          $('#date').removeClass('error-input');
        }
        if (!time) {
          $('#time').addClass('error-input');
          inputError = true;
        } else {
          $('#time').removeClass('error-input');
        }
        if (!code || code && (code.length != 6 || !parseInt(code))) {
          $('#customerCode').addClass('error-input');
          inputError = true;
        } else {
          $('#customerCode').removeClass('error-input');
        }
        if (!message) {
          $('#messageText').addClass('error-input');
          inputError = true;
        } else {
          $('#messageText').removeClass('error-input');
        }
        this.get('warply_sdk').post_context(JSON.stringify({
          'products': {
            action: 'click_away',
            subaction: "add_appointment",
            code: code,
            extra_fields: {
              merchant: branchName ? branchName : null,
              afm: afm ? afm : null,
              dieu8insi: dieu8insi ? dieu8insi : null,
              date: date ? date : null,
              time: time ? time : null,
              comments: message ? message : null,
              clickInside: click
            }
          }
        }), this.congrats.bind(this), this.failure);
      }
    },
    congrats: function (response) {
      console.log(response);
    },
    success: function (data) {
      Ember.set(this, 'codeValue', data.context.MAPP_PRODUCTS.code);
    },
    failure: function () {
      console.log("Error");
    }
  });
});