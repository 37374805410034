define("katanalotis-microsite/templates/components/custom-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "y5MI9lhC",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"showModal\"]]],null,{\"statements\":[[7,\"div\",false],[12,\"class\",\"custom-modal\"],[3,\"action\",[[23,0,[]],[24,[\"closeModal\"]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-backdrop\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[23,0,[\"imageSrc\"]]],[10,\"alt\",\"Modal Image\"],[8],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[23,0,[\"modalText\"]],false],[9],[0,\"\\n      \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],[24,[\"closeModal\"]]]],[8],[0,\"OK\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "katanalotis-microsite/templates/components/custom-modal.hbs"
    }
  });
});