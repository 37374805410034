define("katanalotis-microsite/templates/components/newsletter-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Px1O4KYk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"newsletter-container container-fluid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"newsletter-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"newsletter-message\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"newsletter-title\"],[8],[0,\"Εγγραφείτε στο newsletter\"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"newsletter-subtitle\"],[8],[0,\"και μάθετε πριν από όλους νέα & προσφορές\\n        για ό,τι κι αν χρειάζεστε!\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"form\",true],[10,\"class\",\"newsletter-form\"],[8],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"value\",\"placeholder\"],[\"email\",\"newsletter-input\",[24,[\"email\"]],\"Εισάγετε το email σας\"]]],false],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"newsletter-input-terms\"],[8],[0,\"\\n          Με την εγγραφή αποδέχεσαι τους\\n          \"],[4,\"link-to\",null,[[\"route\"],[\"terms\"]],{\"statements\":[[0,\" \"],[7,\"a\",true],[10,\"href\",\"\"],[8],[0,\"Όρους Χρήσης\"],[9],[0,\". \"]],\"parameters\":[]},null],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"newsletter-error\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"newsletter-submit\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"handleFormSubmission\"]],[8],[0,\"Εγγραφή\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"img\",true],[10,\"src\",\"assets/basket-new.png\"],[10,\"alt\",\"newsletter-image\"],[10,\"class\",\"newsletter-image\"],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"newsletter-image-clip\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "katanalotis-microsite/templates/components/newsletter-form.hbs"
    }
  });
});